import { scrollIntoView } from '../../Utils/scrollIntoView';
import ArrowDown from '../ArrowDown/ArrowDown';
import Button from '../Button';
import { IconsNames } from '../Icon/constants';
import Icon from '../Icon/Icon';

import styles from './Header.module.scss';

const mockupPath: string = require('../../Assets/mockup.png');

function Header() {
  return (
    <div className={styles.header}>
      <div className={styles.content}>
        <div className={styles.left_side_content}>
          <div className={styles.title}>
            Цифровая трансформация технологических процессов
          </div>
          <div className={styles.subtitle}>
            Инновационное решение для обеспечения контроля качества питьевой
            воды, позволяющее автоматизировать внутренние технологические
            процессы водоподготовки
          </div>
          <Button
            isTransparent
            onClick={scrollIntoView('Form')}
            className={styles.button}
          >
            Попробовать
          </Button>
        </div>
        <div className={styles.right_side_content}>
          <img src={mockupPath} alt="mockup" className={styles.mockup_image} />
        </div>
      </div>
      <Icon
        name={IconsNames.HeaderBackground}
        className={styles.header_background}
      />
      <ArrowDown
        className={styles.arrow_down}
        onClick={scrollIntoView('Features')}
      />
    </div>
  );
}

export default Header;
