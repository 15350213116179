import { Bubble } from './BubbleGenerator.interface';

const MIN_BUBBLE_SIZE: number = 20;
const MAX_BUBBLE_SIZE: number = 25;
const BUBBLES_COUNT: number = 10;

export function generateBubbles(canvas: HTMLCanvasElement) {
  let bubbles: Bubble[] = [];
  let width: number = canvas.clientWidth;
  let height: number = canvas.clientHeight;
  const context: CanvasRenderingContext2D = canvas.getContext('2d');

  function generate() {
    for (let i = 0; i < BUBBLES_COUNT; i += 1) {
      bubbles.push({
        x: Math.floor(Math.random() * width),
        y: Math.floor(Math.random() * height),
        size: Math.floor(Math.random() * MAX_BUBBLE_SIZE) + MIN_BUBBLE_SIZE,
        vy: Math.abs(Math.random() * 0.5 + 0.1) * -1,
        vx: Math.random() * 0.1 - 0.1,
        id: i,
      });
    }
  }

  function replaceBubble(bubbleId: number): Bubble[] {
    return bubbles.map((bubble) => {
      if (bubble.id === bubbleId) {
        return {
          x: Math.floor(Math.random() * width),
          y: height,
          size: Math.floor(Math.random() * MAX_BUBBLE_SIZE) + MIN_BUBBLE_SIZE,
          vy: Math.abs(Math.random() * 0.5 + 0.1) * -1,
          vx: Math.random() * 0.1 - 0.1,
          id: bubbleId,
        };
      }

      return bubble;
    });
  }

  function update() {
    for (let i = 0; i < BUBBLES_COUNT; i += 1) {
      const bubble: Bubble = bubbles[i];

      bubble.x += bubble.vx;
      bubble.y += bubble.vy * 2;

      if (
        bubble.x + bubble.size > width ||
        bubble.x + bubble.size < 0 ||
        bubble.y - bubble.size < 0
      ) {
        bubbles = replaceBubble(bubble.id);
      }
    }
  }

  function draw() {
    if (!(context instanceof CanvasRenderingContext2D)) return;

    context.clearRect(0, 0, width, height);

    for (let i = 0; i < BUBBLES_COUNT; i += 1) {
      const bubble: Bubble = bubbles[i];
      context.beginPath();

      context.arc(bubble.x, bubble.y, bubble.size, 0, 2 * Math.PI);
      context.fillStyle = 'rgba(255,255,255, 0.1)';
      context.fill();
    }
  }

  function animate() {
    requestAnimationFrame(animate);
    update();
    draw();
  }

  function setCanvasDimensions() {
    if (!canvas) return;
    canvas.width = window.innerWidth;
    width = canvas.width;
    canvas.height = window.innerHeight;
    height = canvas.height;
  }

  function resizeCanvas() {
    setCanvasDimensions();
  }

  resizeCanvas();
  generate();
  animate();

  window.addEventListener('resize', resizeCanvas);
}
