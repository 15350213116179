import { useState } from 'react';
import {
  HiddenWrapper,
  SmallHeader,
  SystemRequirementsSection,
  TechnicalSupprotSection,
} from '../../Components';

import styles from './TermsPage.module.scss';
import { useLocomotiveScroll } from '../../Utils/Functions/useLocomotiveScroll';

function TermsPage() {
  const [activeSection, setActiveSection] = useState(0);
  useLocomotiveScroll();
  return (
    <div className={styles.page}>
      <SmallHeader
        togglePage={setActiveSection}
        title="Условия использования"
        pagesName={['Требования к установке', 'Техподдержка']}
        activeSection={activeSection}
      />
      <HiddenWrapper active={activeSection === 0 && true}>
        <SystemRequirementsSection />
      </HiddenWrapper>
      <HiddenWrapper active={activeSection === 1 && true}>
        <TechnicalSupprotSection />
      </HiddenWrapper>
    </div>
  );
}

export default TermsPage;
