import { useLayoutEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import Button from '../Button/Button';
import { IconsNames } from '../Icon/constants';
import Icon from '../Icon/Icon';

import styles from './TopNavBar.module.scss';
import { links } from '../../Static/Links';
import ButtonWithBubbles from '../ButtonWithBubbles';
import { TopNavBarPropsType } from './types';

function TopNavBar({ showForm }: TopNavBarPropsType) {
  const [isTransparent, setIsTransparent] = useState<boolean>(false);
  const navLinks: JSX.Element[] = useMemo(
    () =>
      links.map((menuItem) => {
        const { label, to } = menuItem;
        return (
          <NavLink
            to={to}
            key={label}
            className={({ isActive }) =>
              clsx(styles.menu_item, {
                [styles.menu_item_active]: isActive,
              })
            }
          >
            {label}
          </NavLink>
        );
      }),
    [],
  );

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsTransparent(true);
    } else {
      setIsTransparent(false);
    }
  };

  function NewTab() {
    window.open(process.env.REACT_APP_HOST_IP, '_blank');
  }

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const showFormClickHandler = () => {
    showForm();
  };

  return (
    <div
      className={clsx(styles.top_navbar, {
        [styles.transparent]: isTransparent,
      })}
    >
      <Icon name={IconsNames.LogoBright} className={styles.logo} />
      <div className={styles.nav_links}>{navLinks}</div>
      <div className={styles.btns_wrap}>
        {!isTransparent ? (
          <Button isTransparent={isTransparent} onClick={showFormClickHandler}>
            Заказать демо
          </Button>
        ) : (
          <ButtonWithBubbles
            text="Заказать демо"
            onClick={showFormClickHandler}
          />
        )}
        <button type="button" className={styles.menu_item} onClick={NewTab}>
          Войти
        </button>
      </div>
    </div>
  );
}

export default TopNavBar;
