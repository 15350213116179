import { useState } from 'react';
import {
  HiddenWrapper,
  SmallHeader,
  UserInstructionsSection,
  // VideoSection,
} from '../../Components';

import styles from './EducationPage.module.scss';
import { useLocomotiveScroll } from '../../Utils/Functions/useLocomotiveScroll';

function EducationPage() {
  const [activeSection, setActiveSection] = useState(0);
  useLocomotiveScroll();
  return (
    <div className={styles.page}>
      <SmallHeader
        togglePage={setActiveSection}
        title="Обучающие материалы"
        pagesName={['Инструкции пользователя']}
        activeSection={activeSection}
      />
      {/* Скрыто пока не готовы видео на ютубе */}
      {/* <HiddenWrapper active={activeSection === 0 && true}>
        <VideoSection />
      </HiddenWrapper> */}
      <HiddenWrapper active={activeSection === 0 && true}>
        <UserInstructionsSection />
      </HiddenWrapper>
    </div>
  );
}

export default EducationPage;
