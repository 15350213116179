import { useState } from 'react';
import { HiddenWrapper, SmallHeader } from '../../Components';

import styles from './PrivacyPolicyPage.module.scss';
import { createParagraph } from '../../Utils/Functions/createTextFuncitions';
import {
  FINAL_PROVISIONS_PARAGRAPH_DATA,
  GENERAL_PROVISIONS_PARAGRAPH_DATA,
  LEGAL_GROUNDS_PARAGRAPH_DATA,
  PERSONAL_TARGET_PARAGRAPH_DATA,
  PROCEDURE_OF_COLLECTING_PARAGRAPH_DATA,
  WATER_PRO_PARAGRAPH_DATA,
} from './PrivacyTextData';
import { useLocomotiveScroll } from '../../Utils/Functions/useLocomotiveScroll';

function PrivacyPolicyPage() {
  const [activeSection, setActiveSection] = useState(0);
  useLocomotiveScroll();
  return (
    <div className={styles.page}>
      <SmallHeader
        togglePage={setActiveSection}
        title="Политика конфиденциальности"
        pagesName={['']}
        activeSection={activeSection}
      />

      <HiddenWrapper active={activeSection === 0}>
        <div className={styles.container}>
          {createParagraph(GENERAL_PROVISIONS_PARAGRAPH_DATA)}
          {createParagraph(WATER_PRO_PARAGRAPH_DATA)}
          {createParagraph(PERSONAL_TARGET_PARAGRAPH_DATA)}
          {createParagraph(LEGAL_GROUNDS_PARAGRAPH_DATA)}
          {createParagraph(PROCEDURE_OF_COLLECTING_PARAGRAPH_DATA)}
          {createParagraph(FINAL_PROVISIONS_PARAGRAPH_DATA)}
        </div>
      </HiddenWrapper>
    </div>
  );
}

export default PrivacyPolicyPage;
