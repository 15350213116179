export const links = [
  {
    label: 'Главная',
    to: 'main',
  },
  {
    label: 'Условия использования',
    to: 'terms',
  },
  {
    label: 'Обучающие материалы',
    to: 'education',
  },
];

export const docLinks = [
  {
    label: 'Лицензионное соглашение',
    to: 'license',
  },
  {
    label: 'Регламент технической поддержки',
    to: 'technical_support',
  },
  {
    label: 'Политика конфиденциальности',
    to: 'privacy',
  },
];
