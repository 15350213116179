import clsx from 'clsx';
import { useState } from 'react';
import {
  CloudIcon,
  OnCloudIcon,
  OnPremiseIcon,
  ServerIcon,
} from '../../Utils/SvgComponents';
import styles from './CloudSection.module.scss';

function CloudSection() {
  const [activeBtn, setActiveBtn] = useState(0);

  function clickHandler(id: any) {
    setActiveBtn(id);
  }
  return (
    <section className={styles.container}>
      <div className={styles.circles} />
      <div className={styles.content}>
        <button
          className={clsx(styles.btn_pulse, {
            [styles.btn_pulse__active]: activeBtn === 0,
          })}
          type="button"
          onClick={() => clickHandler(0)}
        >
          <div className={styles.btn_pulse__bg}>
            <CloudIcon />
          </div>
        </button>
        <button
          onClick={() => clickHandler(1)}
          className={clsx(styles.btn_pulse, {
            [styles.btn_pulse__active]: activeBtn === 1,
          })}
          type="button"
        >
          <div className={styles.btn_pulse__bg}>
            <ServerIcon />
          </div>
        </button>
        <div className={styles.relative_wrap}>
          <div
            className={clsx(styles.descr_wrap, {
              [styles.descr_wrap__active]: activeBtn === 0,
            })}
          >
            <div className={styles.descr_item}>
              <span className={styles.blue_circle} />
              <span className={styles.descr_item__text}>
                Предоставляется в виде интернет-сервиса SaaS
              </span>
            </div>
            <div className={styles.descr_item}>
              <span className={styles.blue_circle} />
              <span className={styles.descr_item__text}>
                Не требует установки, поддержки аппаратной инфраструктуры и
                навыков администрирования
              </span>
            </div>
            <div className={styles.descr_item}>
              <span className={styles.blue_circle} />
              <span className={styles.descr_item__text}>
                Мгновенный доступ к обновлениям и новым версиям продукта
              </span>
            </div>
            <div className={styles.descr_item}>
              <span className={styles.blue_circle} />
              <span className={styles.descr_item__text}>
                Обеспечивает гибкость и легкую масштабируемость
              </span>
            </div>
          </div>
          <div
            className={clsx(styles.descr_wrap, {
              [styles.descr_wrap__active]: activeBtn === 1,
            })}
          >
            <div className={styles.descr_item}>
              <span className={styles.blue_circle} />
              <span className={styles.descr_item__text}>
                ПО устанавливается и обслуживается на собственных серверах или
                оборудовании компании заказчика
              </span>
            </div>
            <div className={styles.descr_item}>
              <span className={styles.blue_circle} />
              <span className={styles.descr_item__text}>
                Требуется обслуживание и обновление оборудования
              </span>
            </div>
            <div className={styles.descr_item}>
              <span className={styles.blue_circle} />
              <span className={styles.descr_item__text}>
                Установка обновлений собственными силами
              </span>
            </div>
            <div className={styles.descr_item}>
              <span className={styles.blue_circle} />
              <span className={styles.descr_item__text}>
                Ограниченность собственных ресурсов и дополнительные расходы
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.big_title}>
        <div
          className={clsx(styles.icon_wrap, {
            [styles.icon_wrap__active]: activeBtn === 0,
          })}
        >
          <OnCloudIcon />
        </div>
        <div
          className={clsx(styles.icon_wrap, {
            [styles.icon_wrap__active]: activeBtn === 1,
          })}
        >
          <OnPremiseIcon />
        </div>
      </div>
    </section>
  );
}

export default CloudSection;
