export enum IconsNames {
  LogoWhite = 'LogoWhite',
  LogoBright = 'LogoBright',
  HeaderBackground = 'HeaderBackground',
  HeaderBackgroundSmall = 'HeaderBackgroundSmall',
  FooterBackground = 'FooterBackground',
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
  GradientCircle = 'GradientCircle',
}
