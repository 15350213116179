import React, { MutableRefObject, useLayoutEffect, useRef } from 'react';

import { generateBubbles } from './generateBubbles';

import styles from './BubbleGenerator.module.scss';

function BubbleGenerator() {
  const canvasRef: MutableRefObject<HTMLCanvasElement> = useRef(null);

  useLayoutEffect(() => {
    if (canvasRef.current !== null) {
      generateBubbles(canvasRef.current);
    }
  }, [canvasRef.current !== null]);

  return (
    <div className={styles.bubble_generator}>
      <canvas className={styles.canvas} ref={canvasRef} />
    </div>
  );
}

export default BubbleGenerator;
