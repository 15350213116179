import {
  createParagraph,
  createTextField,
} from '../../Utils/Functions/createTextFuncitions';
import styles from './UserInstructionsSection.module.scss';
import {
  APPLICATION_PARAGRAPH_DATA,
  AUTH_PARAGRAPH_DATA,
  INTERFACE_ADMIN_PANEL_TEXT,
  INTERFACE_STANDART_TEXT,
  INTERFACE_SYNC_REPORT_TEXT,
  LABORANT_PARAGRAPH_DATA,
  MONITORING_LAB_ADMIN_PARAGRAPH_DATA,
  MONITORING_TECH_PARAGRAPH_DATA,
  PASSWORD_PARAGRAPH_DATA,
  REAGENT_ADMIN_PARAGRAPH_DATA,
  REAGENT_LAB_TECH_PARAGRAPH_DATA,
  REGISTRATION_PARAGRAPH_DATA,
  TECH_AND_ADMIN_PARAGRAPH_DATA,
} from './paragraphsData';

function UserInstructionsSection() {
  return (
    <div className={styles.container}>
      <h1 className={styles.main_title}>Инструкции пользователя</h1>
      <h2 className={styles.sub_title}>Регистрация и авторизация</h2>
      {createParagraph(REGISTRATION_PARAGRAPH_DATA)}
      {createParagraph(AUTH_PARAGRAPH_DATA)}
      {createParagraph(APPLICATION_PARAGRAPH_DATA)}
      {createParagraph(PASSWORD_PARAGRAPH_DATA)}
      <h2 className={styles.sub_title}>
        Интерфейс взаимодействия пользователя “Лабораторные исследования”
      </h2>
      {createParagraph(LABORANT_PARAGRAPH_DATA)}
      {createParagraph(TECH_AND_ADMIN_PARAGRAPH_DATA)}
      <h2 className={styles.sub_title}>
        Интерфейс взаимодействия пользователя “Мониторинг”
      </h2>
      {createParagraph(MONITORING_TECH_PARAGRAPH_DATA)}
      {createParagraph(MONITORING_LAB_ADMIN_PARAGRAPH_DATA)}
      <h2 className={styles.sub_title}>
        Интерфейс взаимодействия пользователя “Реагенты”
      </h2>
      {createParagraph(REAGENT_LAB_TECH_PARAGRAPH_DATA)}
      {createParagraph(REAGENT_ADMIN_PARAGRAPH_DATA)}
      <h2 className={styles.sub_title}>
        Интерфейс взаимодействия пользователя “Стандарты”
      </h2>
      {createTextField(INTERFACE_STANDART_TEXT)}
      <h2 className={styles.sub_title}>
        Интерфейс взаимодействия пользователя “Синхронизация отчетности”
      </h2>
      {createTextField(INTERFACE_SYNC_REPORT_TEXT)}
      <h2 className={styles.sub_title}>
        Интерфейс взаимодействия пользователя “Панель администратора системы”
      </h2>
      {createTextField(INTERFACE_ADMIN_PANEL_TEXT)}
    </div>
  );
}

export default UserInstructionsSection;
