import clsx from 'clsx';
import styles from '../../Assets/styles/textFields.module.scss';
import { openMailApplication } from '../../Utils/Functions/openMailApplication';

export const LICENSE_AGREEMENT_TEXT = [
  <p className={styles.mb_15} key={1}>
    Настоящее лицензионное соглашение с конечным пользователем (далее —
    «Соглашение») является юридическим соглашением между вами (юридическим, или
    физическим лицом, далее именуемое «<span className={styles.bold}>Вы</span>»
    или «<span className={styles.bold}>Конечный пользователь</span>») и ООО
    «АУТОМЭЙТ-БИЗНЕС», ИНН 5260477404, ОГРН 1215200013830, (далее — «
    <span className={styles.bold}>Правообладатель</span>») в отношении
    использования программы для ЭВМ Водоканал PRO, исключительное право на
    которую принадлежит Правообладателю (далее - «
    <span className={styles.bold}>Программное обеспечение</span>»).
  </p>,
  <p className={clsx(styles.mb_15, styles.bold)} key={2}>
    ВНИМАТЕЛЬНО ИЗУЧИТЕ ДАННОЕ СОГЛАШЕНИЕ! ПРИОБРЕТАЯ, УСТАНАВЛИВАЯ ДАННОЕ
    ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ (ВКЛЮЧАЯ ЕГО КОМПОНЕНТЫ) ЛИБО ИСПОЛЬЗУЯ ЕГО ЛЮБЫМ
    ДРУГИМ ОБРАЗОМ, ВЫ ТЕМ САМЫМ БЕЗОГОВОРОЧНО ПРИНИМАЕТЕ УСЛОВИЯ НАСТОЯЩЕГО
    СОГЛАШЕНИЯ. В СЛУЧАЕ НЕСОГЛАСИЯ С ЭТИМИ УСЛОВИЯМИ ВАМ ЗАПРЕЩАЕТСЯ ЗАГРУЖАТЬ,
    УСТАНАВЛИВАТЬ И ИСПОЛЬЗОВАТЬ ДАННОЕ ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ. ЕСЛИ ВЫ
    ЯВЛЯЕТЕСЬ ФИЗИЧЕСКИМ ЛИЦОМ, ДЕЙСТВУЮЩИМ ОТ ИМЕНИ ЮРИДИЧЕСКОГО ЛИЦА, ТО ВЫ
    НАСТОЯЩИМ ГАРАНТИРУЕТЕ НАЛИЧИЕ У ВАС ВСЕХ ЮРИДИЧЕСКИ ДЕЙСТВИТЕЛЬНЫХ
    ПОЛНОМОЧИЙ, НЕОБХОДИМЫХ ДЛЯ ЗАКЛЮЧЕНИЯ НАСТОЯЩЕГО СОГЛАШЕНИЯ ОТ ИМЕНИ ТАКОГО
    ЮРИДИЧЕСКОГО ЛИЦА.
  </p>,
  <p className={clsx(styles.mb_15, styles.bold)} key={3}>
    УСЛОВИЯ НАСТОЯЩЕГО СОГЛАШЕНИЯ МОГУТ БЫТЬ ПРИНЯТЫ ВАМИ, ТОЛЬКО ЕСЛИ ВЫ
    ПРАВОМЕРНО ПРИОБРЕЛИ ПРАВО ИСПОЛЬЗОВАНИЯ ИЛИ НАДЛЕЖАЩЕ ВВЕДЕННЫЙ В
    ГРАЖДАНСКИЙ ОБОРОТ ЭКЗЕМПЛЯР ПРОГРАММНОГО ОБЕСПЕЧЕНИЯ. В ПРОТИВНОМ СЛУЧАЕ,
    ВЫ НЕ МОЖЕТЕ ЯВЛЯТЬСЯ СТОРОНОЙ НАСТОЯЩЕГО СОГЛАШЕНИЯ И НЕ МОЖЕТЕ ПРИНЯТЬ ЕГО
    УСЛОВИЯ, А ЛЮБОЕ ИСПОЛЬЗОВАНИЕ ВАМИ ПРОГРАММНОГО ОБЕСПЕЧЕНИЯ БУДЕТ ЯВЛЯТЬСЯ
    НАРУШЕНИЕМ ИСКЛЮЧИТЕЛЬНОГО ПРАВА ПРАВООБЛАДАТЕЛЯ.
  </p>,
];

export const PROGRAM_PARAGRAPH_DATA = [
  {
    title: 'Программное обеспечение',
    id: 1,
    rows: [
      <div key={1} className={styles.row}>
        Объекты, которые в соответствии с настоящим Соглашением охватываются
        термином «Программное обеспечение», включают в себя:
        <div className={styles.flex_dot}>
          <span className={styles.dot} /> саму программу для ЭВМ, как составное
          произведение;
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          отдельные элементы составного произведения (программные пакеты,
          модули, компоненты, библиотеки и т.д.) исключительное право в
          отношении которых принадлежит Правообладателю, включенные
          Правообладателем в составное произведение наряду с другими элементами;
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          отдельные элементы составного произведения исключительное право в
          отношении которых принадлежит третьим лицам (программные пакеты,
          модули, компоненты, библиотеки и т.д.), включенные в составное
          произведение с согласия их соответствующих правообладателей;
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          все содержимое материальных носителей и иной коммуникации, которые
          сопровождаются этим Соглашением, включая экземпляры программы для ЭВМ,
          направляемые, по электронной почте или путем загрузки через Интернет;
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          любые пояснительные материалы и документацию, которые относятся к
          Программному обеспечению, в том числе любые описания Программного
          обеспечения, его спецификации, описания свойств и работы Программного
          обеспечения, описание рабочей среды использования Программного
          обеспечения, инструкции по эксплуатации и установке и любые другие
          описания по использованию Программного обеспечения (далее — «
          <span className={clsx(styles.bold, styles.contents)}>
            Документация
          </span>
          »);
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          любые исправления возможных ошибок в Программном обеспечении,
          дополнения, расширения, патчи, отладочные выпуски, новые или
          измененные (модифицированные) версии и обновления компонентов
          Программного обеспечения, если таковые выпускаются Правообладателем, и
          в отношении которых Правообладатель или иной уполномоченный лицензиар
          предоставляет Вам лицензию на использование в соответствии с настоящим
          Соглашением. По общему правилу Правообладатель распространяет
          Программное обеспечение в форме исполняемого объектного кода.
          Предоставление исходного кода Программного обеспечения возможно по
          отдельным запросам Конечного пользователя, направляемым на условиях
          настоящего Соглашения.
        </div>
      </div>,
    ],
  },
];

export const INSTALL_PARAGRAPH_DATA = [
  {
    title: 'Установка',
    id: 2,
    rows: [
      <div key={1} className={styles.row}>
        В случае, если используемая Вами конфигурация Программного обеспечения
        требует установки, такая установка должна происходить на должным образом
        настроенной ЭВМ, которая отвечает минимальным требованиям, изложенным в
        Документации. ЭВМ, на которой выполняется установка, не должна содержать
        программное или аппаратное обеспечение, которое может негативно повлиять
        на работу Программного обеспечения. В отдельных случаях запуск и
        использование Программного обеспечения возможен непосредственно с
        внешнего носителя без его установки, и тогда к оборудованию, на котором
        таким образом используется Программное обеспечение, предъявляются
        требования, аналогичные вышеуказанным.
      </div>,
    ],
  },
];

export const LICENSE_PARAGRAPH_DATA = [
  {
    title: 'Лицензия',
    id: 3,
    rows: [
      <div key={1} className={styles.row}>
        Если Вы являетесь правомерным приобретателем права использования или
        надлежаще введенного в гражданский оборот экземпляра Программного
        обеспечения и приняли условия настоящего Соглашения, то Правообладатель
        на условиях простой (неисключительной) лицензии предоставляет Вам право
        использования Программного обеспечения в соответствии с нижеследующими
        способами и ограничениями такого использования (далее — «Лицензия»):
      </div>,
      <div key={2} className={styles.row}>
        <span className={styles.bold}>Право на установку и использование.</span>{' '}
        Вы можете установить и использовать Программное обеспечение в
        соответствии с условиями, которые будут установлены Правообладателем или
        уполномоченным Правообладателем лицом при продаже Вам Лицензии и которые
        в частности, но не ограничиваясь, могут содержать ограничения по
        количеству устройство, на которых может использоваться Программное
        обеспечение, количеству пользователей, которые могут иметь доступ к
        Программному обеспечению, количеству иных действий, совершаемых при
        помощи Программного обеспечения и пр.
      </div>,
      <div key={3} className={styles.row}>
        <span className={styles.bold}>Срок Лицензии.</span> Если иного не
        предусмотрено в отдельном договоре, заключаемом между Вами и
        Правообладателем, либо в договоре, заключаемом между Вами и иным
        лицензиаром, уполномоченным на это Правообладателем, Ваше право на
        использование Программного обеспечения ограничено сроком действия
        исключительного права на Программное обеспечение (бессрочно).
      </div>,
      <div key={4} className={styles.row}>
        <span className={styles.bold}>Территория Лицензии.</span> Если иного не
        предусмотрено в отдельном договоре, заключаемом между Вами и
        Правообладателем, либо в договоре, заключаемом между Вами и иным лицом,
        уполномоченным на это Правообладателем, Вы вправе использовать
        Программное обеспечение на территории Российской Федерации.
      </div>,
    ],
  },
];

export const CIRCLE_OF_PEOPLE_PARAGRAPH_DATA = [
  {
    title: 'Круг лиц, которым разрешено использовать Программное обеспечение',
    id: 4,
    rows: [
      <div key={1} className={styles.row}>
        Если Вы являетесь физическим лицом, то Программное обеспечение может
        использоваться только Вами лично. Если Вы являетесь юридическим лицом,
        то Программное обеспечение могут использовать только Ваши сотрудники, а
        также внешние подрядчики и консультанты, но исключительно в целях
        обеспечения внутренней деятельности Вашей организации.
      </div>,
    ],
  },
];

export const REST_OF_RIGHTS_PARAGRAPH_DATA = [
  {
    title: 'Ограничения прав',
    id: 5,
    rows: [
      <div key={1} className={styles.row}>
        При использовании Программного обеспечения Вы обязаны соблюдать
        нижеуказанные ограничения:
      </div>,
      <div key={2} className={styles.row}>
        Вы не вправе предоставлять полученную Вами Лицензию полностью или
        частично третьим лицам, либо давать указанным лицам доступ к
        использованию Программного обеспечения по Вашей лицензии, даже если
        указанные третьи лица являются аффилированными по отношению к Вам.
      </div>,
      <div key={3} className={styles.row}>
        Вы можете создавать резервные копии Программного обеспечения на носителе
        постоянного хранения данных при условии, что эти резервные копии не
        установлены и не используются ни на какой ЭВМ. Создание любых иных копий
        Программного обеспечения является нарушением настоящего Соглашения.
      </div>,
      <div key={4} className={styles.row}>
        Вы не должны изменять, переводить или воспроизводить Программное
        обеспечение и передавать права на использование Программного обеспечения
        или экземпляры Программного обеспечения любым способом, отличным от
        описанного в настоящем Соглашении.
      </div>,
      <div key={5} className={styles.row}>
        Вы не должны продавать, передавать на условиях сублицензии, сдавать в
        аренду или передавать во временное пользование третьим лицам Программное
        обеспечение, а также использовать Программное обеспечение совместно с
        третьими лицами в режиме таймшеринга или сервисного бюро.
      </div>,
      <div key={6} className={styles.row}>
        Вы соглашаетесь использовать Программное обеспечение только способом,
        соответствующим всем действующим законодательным нормам страны, в
        которой используется Программное обеспечение, в том числе применимым
        ограничениям относительно авторского права, других прав на
        интеллектуальную собственность.
      </div>,
    ],
  },
];

export const COPYRIGHT_PARAGRAPH_DATA = [
  {
    title: 'Авторское право',
    id: 6,
    rows: [
      <div key={1} className={styles.row}>
        Все права на Документацию и Программное обеспечение, как на составное
        произведение, включая исключительное право, принадлежат Правообладателю.
        При этом, в состав Программного обеспечения включены компоненты
        Программного обеспечения, исключительное право в отношении которых
        принадлежит третьим лицам, включенные в Программное обеспечение с
        разрешения их соответствующих правообладателей, и все права на такие
        элементы, включая исключительное право, принадлежат их соответствующим
        правообладателям. Все вышеуказанные права защищены законодательством
        Российской Федерации, а также международными соглашениями и всеми
        прочими применимыми законодательными нормами страны, в которой
        используется Программное обеспечение. Любые копии, которые разрешено
        создать в соответствии с Соглашением, должны содержать оригинальные
        отметки о защите авторских прав и другие уведомления о правах
        интеллектуальной собственности, которые присутствуют в самом Программном
        обеспечении.
      </div>,
    ],
  },
];

export const THIRD_PEOPLE_PARAGRAPH_DATA = [
  {
    title: 'Программное обеспечение третьих лиц',
    id: 7,
    rows: [
      <div key={1} className={styles.row}>
        В Программном обеспечении может использоваться программное обеспечение,
        библиотеки и прочие объекты авторского права, исключительное право на
        которые принадлежат третьим лицам и которые распространяются указанными
        третьими лицами на условиях свободных лицензий (Open Source). Во
        исполнение всех требований таких лицензий, Правообладатель разместил в
        составе файлов Программного обеспечения всю необходимую информацию о
        перечне таких объектов интеллектуальной собственности, включенных в
        Программное обеспечение, их авторах, а также всю иную информацию,
        обязательства по указанию которой Правообладатель может нести в
        соответствии с условиями лицензий. Данное Соглашение относится только к
        Программному обеспечению и не ограничивает Ваши права и не предоставляет
        дополнительные права согласно условиям лицензии на использованное
        программное обеспечение третьих лиц.
      </div>,
    ],
  },
];

export const RIGHTS_RESERVED_PARAGRAPH_DATA = [
  {
    title: 'Сохранение прав',
    id: 8,
    rows: [
      <div key={1} className={styles.row}>
        Все права на Программное обеспечение сохраняются за Правообладателем.
        Все права, которые в явной форме передаются Вам как Конечному
        пользователю Программного обеспечения по настоящему Соглашению,
        предоставляются исключительно на условиях простой (неисключительной)
        лицензии.
      </div>,
    ],
  },
];

export const LANGUAGE_OF_AGREEMENTS_PARAGRAPH_DATA = [
  {
    title: 'Язык соглашения',
    id: 9,
    rows: [
      <div key={1} className={styles.row}>
        Если согласно законам страны местонахождения вашей компании договор
        должен быть составлен на государственном языке, чтобы иметь законную
        силу, то Вы можете осуществить перевод настоящего Соглашения на
        соответствующий язык, однако, в этом случае преимущественную силу всё
        равно будет иметь оригинальный текст настоящего Соглашения на русском
        языке, и в случае каких-либо противоречий между оригинальным текстом и
        осуществлённым Вами переводом, применяться будет оригинальный текст
        настоящего Соглашения. Если Программное обеспечение является обновлением
        или вспомогательной версией, то для установки и использования обновления
        или вспомогательной версии вы должны иметь действующую лицензию на
        Программное обеспечение той версии и в том количестве, для которых
        предназначается обновление или вспомогательная версия.
      </div>,
    ],
  },
];

export const MOMENT_OF_AGREEMENTS_PARAGRAPH_DATA = [
  {
    title: 'Момент вступления в силу и прекращение действия Соглашения',
    id: 10,
    rows: [
      <div key={1} className={styles.row}>
        Настоящее Соглашение вступает в законную силу с момента принятия Вами
        его условий. Прекратить действие Соглашения можно в любой момент,
        необратимо удалив, уничтожив или вернув за свой счет Программное
        обеспечение, все резервные копии и любые относящиеся к нему материалы,
        предоставленные Правообладателем или одним из его коммерческих
        партнеров. Независимо от способа прекращения действия Соглашения
        положения статей 6, 7, 10, 12, 19 и 21 остаются действительными без
        ограничения по времени.
      </div>,
    ],
  },
];

export const STOP_LICENSE_PARAGRAPH_DATA = [
  {
    title: 'Момент вступления в силу и прекращение действия Соглашения',
    id: 11,
    rows: [
      <div key={1} className={styles.row}>
        Действие Лицензии прекращается автоматически по окончании периода, на
        который она была выдана. Если Вы нарушаете любое положение настоящего
        Соглашения, Правообладатель получает право расторгнуть его, что никак не
        повлияет на возможности Правообладателя воспользоваться любыми правами,
        доступными ему по закону в этом случае. В случае прекращения Лицензии Вы
        обязаны немедленно за собственный счет удалить, уничтожить или вернуть
        Программное обеспечение и все его резервные копии Правообладателю или
        лицу, у которого оно было приобретено.
      </div>,
    ],
  },
];

export const GUARANTEES_PARAGRAPH_DATA = [
  {
    title: 'ГАРАНТИИ КОНЕЧНОГО ПОЛЬЗОВАТЕЛЯ',
    id: 12,
    rows: [
      <div key={1} className={styles.row}>
        ВЫСТУПАЯ В КАЧЕСТВЕ КОНЕЧНОГО ПОЛЬЗОВАТЕЛЯ, ВЫ ПОДТВЕРЖДАЕТЕ СВОЮ
        ОСВЕДОМЛЕННОСТЬ В ТОМ, ЧТО ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ ПОСТАВЛЯЕТСЯ НА
        УСЛОВИЯХ «КАК ЕСТЬ» БЕЗ КАКИХ-ЛИБО ПРЯМЫХ ИЛИ ВМЕНЕННЫХ ГАРАНТИЙ ЛЮБОГО
        ТИПА, НАСКОЛЬКО ЭТО ПОЗВОЛЯЮТ СООТВЕТСТВУЮЩИЕ ЗАКОНОДАТЕЛЬНЫЕ НОРМЫ. НИ
        ПРАВООБЛАДАТЕЛЬ, НИ ЕГО ПАРТНЕРЫ, ВЫСТУПАЮЩИЕ В КАЧЕСТВЕ ЛИЦЕНЗИАРОВ ИЛИ
        АФФИЛИРОВАННЫХ ЛИЦ, НЕ ДЕЛАЮТ НИКАКИХ ЗАЯВЛЕНИЙ И НЕ ПРЕДОСТАВЛЯЮТ
        НИКАКИХ ПРЯМЫХ ИЛИ ВМЕНЕННЫХ ОБЯЗАТЕЛЬСТВ ИЛИ ГАРАНТИЙ, В ЧАСТНОСТИ
        ГАРАНТИЙ ПРОДАЖ ИЛИ ГАРАНТИЙ ПРИГОДНОСТИ ДЛЯ ОПРЕДЕЛЕННОГО
        ИСПОЛЬЗОВАНИЯ, А ТАКЖЕ ГАРАНТИЙ ТОГО, ЧТО ЭТО ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ НЕ
        НАРУШАЕТ НИКАКИХ ПАТЕНТОВ, АВТОРСКИХ ПРАВ, ПРАВ НА ТОВАРНЫЕ ЗНАКИ И
        ДРУГИХ ПРАВ ТРЕТЬИХ ЛИЦ. ПРАВООБЛАДАТЕЛЬ И ЛЮБЫЕ ДРУГИЕ ЛИЦА НЕ
        ГАРАНТИРУЮТ, ЧТО ФУНКЦИИ ПРОГРАММНОГО ОБЕСПЕЧЕНИЯ БУДУТ СООТВЕТСТВОВАТЬ
        ВАШИМ ТРЕБОВАНИЯМ ИЛИ ЧТО ПРОГРАММНОЕ ОБЕСПЕЧЕНИЕ БУДЕТ РАБОТАТЬ БЕЗ
        СБОЕВ И ОШИБОК. ВСЯ ОТВЕТСТВЕННОСТЬ И РИСК ПРИ ВЫБОРЕ ПРОГРАММНОГО
        ОБЕСПЕЧЕНИЯ ДЛЯ ДОСТИЖЕНИЯ НУЖНЫХ РЕЗУЛЬТАТОВ, А ТАКЖЕ ПРИ УСТАНОВКЕ,
        ИСПОЛЬЗОВАНИИ И ПОЛУЧЕНИИ РЕЗУЛЬТАТОВ, КОТОРЫХ ВЫ БУДЕТЕ ДОСТИГАТЬ С
        ПОМОЩЬЮ ЭТОГО ПРОГРАММНОГО ОБЕСПЕЧЕНИЯ, ЛЕЖАТ НА ВАС.
      </div>,
    ],
  },
];

export const REJECT_PARAGRAPH_DATA = [
  {
    title: 'Отказ от дальнейших обязательств',
    id: 13,
    rows: [
      <div key={1} className={styles.row}>
        Настоящее Соглашение не накладывает никаких обязательств на
        Правообладателя и его лицензиаров за исключением тех, что изложены в
        настоящем Соглашении.
      </div>,
    ],
  },
];

export const LIMITATION_PARAGRAPH_DATA = [
  {
    title: 'ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ',
    id: 14,
    rows: [
      <div key={1} className={styles.row}>
        В ТОЙ СТЕПЕНИ, В КОТОРОЙ ЭТО РАЗРЕШЕНО ПРИМЕНИМЫМ ЗАКОНОДАТЕЛЬСТВОМ, НИ
        В КОЕМ СЛУЧАЕ ПРАВООБЛАДАТЕЛЬ, ЕГО СОТРУДНИКИ И ЛИЦЕНЗИАРЫ НЕ НЕСУТ
        ОТВЕТСТВЕННОСТИ ЗА УПУЩЕННУЮ ПРИБЫЛЬ, ПОТЕРИ ДОХОДОВ ИЛИ ОБОРОТА С
        ПРОДАЖ, ЗА УТРАТУ ДАННЫХ, А ТАКЖЕ ПО ЗАТРАТАМ НА ПРИОБРЕТЕНИЕ ЗАМЕНЯЮЩИХ
        ТОВАРОВ ИЛИ УСЛУГ, ЗА ПОРЧУ ИМУЩЕСТВА, ВРЕД ЗДОРОВЬЮ, ПЕРЕРЫВЫ В
        КОММЕРЧЕСКОЙ ДЕЯТЕЛЬНОСТИ, ПОТЕРЮ КОММЕРЧЕСКОЙ ИНФОРМАЦИИ ИЛИ ДРУГИЕ
        СЛУЧАИ УЩЕРБА, А ТАКЖЕ ЛЮБЫЕ ИНЫЕ УБЫТКИ, КОТОРЫЕ МОГУТ БЫТЬ ОТНЕСЕНЫ К
        УПУЩЕННОЙ ВЫГОДЕ. КРОМЕ ТОГО, ВОЗМЕЩЕНИЕ РЕАЛЬНОГО УЩЕРБА
        ПРАВООБЛАДАТЕЛЕМ ТАКЖЕ ОГРАНИЧЕНО СТОИМОСТЬЮ, КОТОРАЯ БЫЛА ВЫПЛАЧЕННА
        ВАМИ ЗА ЛИЦЕНЗИЮ.
      </div>,
    ],
  },
];

export const TRANSLATE_PO_PARAGRAPH_DATA = [
  {
    title: 'Передача Программного обеспечения',
    id: 15,
    rows: [
      <div key={1} className={styles.row}>
        Программное обеспечение может быть перенесено с одной ЭВМ на другую ЭВМ
        в пределах Вашей организации, если это не противоречит условиям
        настоящего Соглашения. Конечный пользователь не может передать Лицензию
        и все права по настоящему Соглашению другому Конечному пользователю,
        кроме как с согласия Правообладателя.
      </div>,
    ],
  },
];

export const CHECK_PO_PARAGRAPH_DATA = [
  {
    title: 'Проверка подлинности Программного обеспечения',
    id: 16,
    rows: [
      <div key={1} className={styles.row}>
        Конечный пользователь может продемонстрировать наличие у него прав на
        использование Программного обеспечения одним из следующих способов:
        <div className={styles.flex_dot}>
          <span className={styles.dot} /> с помощью лицензионного свидетельства,
          выданного Правообладателем или назначенным Правообладателем третьим
          лицом;
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          письменным лицензионным соглашением, если таковое было заключено;
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          путем предоставления отправленного Правообладателем сообщения
          электронной почты, в котором содержатся сведения о лицензии.
        </div>
      </div>,
    ],
  },
];

export const EXPERT_PO_PARAGRAPH_DATA = [
  {
    title: 'Соблюдение экспортного законодательства',
    id: 17,
    rows: [
      <div key={1} className={styles.row}>
        Программное обеспечение, Документация или их компоненты, включая
        информацию о Программном обеспечении и его компонентах, могут попадать
        под действие правил и ограничений экспорта и импорта, введённых
        Российской Федерацией. Вы обязуетесь строго следовать всем применимым
        нормам и правилам по экспорту и импорту и подтверждаете, что Вы
        ответственны за получение любых лицензий на экспорт, реэкспорт, передачу
        и импорт Программного обеспечения.
      </div>,
    ],
  },
];

export const ALLERT_PARAGRAPH_DATA = [
  {
    title: 'Уведомления',
    id: 18,
    rows: [
      <div key={1} className={styles.row}>
        Все уведомления, возвращаемое Программное обеспечение и Документация
        подлежат направлению по адресу:{' '}
        <button
          type="button"
          onClick={openMailApplication('info@vodokanal-pro.ru')}
          className={styles.link}
        >
          info@vodokanal-pro.ru
        </button>
      </div>,
    ],
  },
];

export const APPLICABLE_LAW_PARAGRAPH_DATA = [
  {
    title: 'Применимое законодательство',
    id: 19,
    rows: [
      <div key={1} className={styles.row}>
        Настоящее Соглашение регулируется и толкуется в соответствии с
        законодательством Российской Федерации. Вы соглашаетесь на
        исключительную подсудность при разрешении любых споров с
        Правообладателем по настоящему Соглашению в Арбитражном суде г. Москвы.
      </div>,
    ],
  },
];

export const GENERAL_PROVISIOBS_PARAGRAPH_DATA = [
  {
    title: 'Общие положения',
    id: 20,
    rows: [
      <div key={1} className={styles.row}>
        Если какое-либо положение настоящего Соглашения признается
        недействительным или иным образом не имеющим законной силы, это не
        отражается на действительности остальных положений Соглашения, которые
        по-прежнему будут действительными и имеющими законную силу в
        соответствии с указанными здесь условиями. Любые изменения к настоящему
        Соглашению могут иметь место только в письменной форме и должны быть
        подписаны действующим на основе закона компетентным и уполномоченным
        представителем Правообладателя.
      </div>,
    ],
  },
];

export const ENTIRE_AGREEMENT_PARAGRAPH_DATA = [
  {
    title: 'Полнота Соглашения',
    id: 21,
    rows: [
      <div key={1} className={styles.row}>
        Настоящее Соглашение между Правообладателем и Вами является единственным
        и полным Соглашением относительно Программного обеспечения, и полностью
        отменяет любые предыдущие заявления, результаты переговоров,
        обязательства, отчеты или объявления относительно Программного
        обеспечения.
      </div>,
    ],
  },
];
