import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import styles from '../../Assets/styles/textFields.module.scss';
import { openMailApplication } from '../../Utils/Functions/openMailApplication';

export const GENERAL_PROVISIONS_PARAGRAPH_DATA = [
  {
    title: 'Общие положения',
    id: 1,
    rows: [
      <div key={1} className={styles.row}>
        Настоящая Политика является неотъемлемой частью Пользовательского
        соглашения, размещенного по ссылке{' '}
        <NavLink
          className={clsx(styles.link, styles.contents)}
          to="/user_agreement"
        >
          www.vodokanal-pro.ru/user_agreement
        </NavLink>{' '}
        Все термины, использованные в настоящей Политике, имеют значения,
        установленные в Пользовательском соглашении.
      </div>,
      <div key={2} className={styles.row}>
        Водоканал PRO ставит соблюдение прав и свобод граждан одним из важнейших
        условий осуществления своей деятельности.
      </div>,
      <div key={3} className={styles.row}>
        Использование Сайта означает безоговорочное согласие Пользователя с
        настоящей Политикой и указанными в ней условиями обработки его
        персональной информации, а именно на совершение действий,
        предусмотренных п. 3 ч. 1 ст. 3 Федерального закона от 27.07.2006 N
        152-ФЗ &ldquo;О персональных данных&rdquo; как без, так и с
        использованием средств автоматизации, и подтверждает, что, давая такое
        согласие, он действует свободно, своей волей и в своем интересе; в
        случае несогласия с этими условиями Пользователь должен воздержаться от
        использования Сайта.
      </div>,
    ],
  },
];

export const WATER_PRO_PARAGRAPH_DATA = [
  {
    title:
      'Водоканал PRO может обрабатывать следующие персональные данные Пользователя:',
    id: 2,
    rows: [
      <div key={1} className={styles.row}>
        Водоканал PRO может осуществлять сбор и обработку следующих персональных
        данных Пользователя:
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Ваша фамилия, имя, отчество;
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Ваш номер телефона;
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Ваш адрес электронной почты;
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          электронные данные (HTTP-заголовки, IP-адрес, файлы cookie,
          веб-маяки/пиксельные теги, данные об идентификаторе браузера,
          информация об аппаратном и программном обеспечении);
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          дата и время осуществления доступа к Сайту;
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          информация о Вашей активности во время использования Сайта (например,
          история поисковых запросов);
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          иные данные, собираемые с помощью сервисов интернет-статистики (Яндекс
          Метрика, Гугл Аналитика и пр.).
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          прочая персональная информация, которую Пользователь предоставляет о
          себе самостоятельно в ходе использования Сайта.
        </div>
      </div>,
    ],
  },
];

export const PERSONAL_TARGET_PARAGRAPH_DATA = [
  {
    title: 'Цели обработки персональных данных',
    id: 3,
    rows: [
      <div key={1} className={styles.row}>
        Персональные данные Пользователя обрабатываются со следующей целью:
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          идентификация стороны в рамках заказа Пользователя;
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          уточнение деталей и отправка заказа Пользователя;
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          почтовая рассылка новостей компании;
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          почтовая рассылка маркетинговых материалов компании;
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          выполнение договорных обязательств перед Пользователем;
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          связь с Пользователем, в том числе направление уведомлений, запросов и
          информации;
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          таргетирование рекламных материалов;
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          проведение статистических и иных исследований на основе обезличенных
          данных;
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Водоканал PRO имеет право направлять Пользователю уведомления о новых
          продуктах и услугах, специальных предложениях и различных событиях.
          Пользователь всегда может отказаться от получения информационных
          сообщений, направив Водоканал PRO письмо на адрес{' '}
          <button
            type="button"
            onClick={openMailApplication('info@vodokanal-pro.ru')}
            className={clsx(styles.link, styles.contents)}
          >
            info@vodokanal-pro.ru
          </button>
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Обезличенные данные Пользователей, собираемые с помощью сервисов
          интернет-статистики, служат для сбора информации о действиях
          Пользователей на сайте, улучшения качества сайта и его содержания.
        </div>
      </div>,
    ],
  },
];

export const LEGAL_GROUNDS_PARAGRAPH_DATA = [
  {
    title: 'Правовые основания обработки персональных данных',
    id: 4,
    rows: [
      <div key={1} className={styles.row}>
        Водоканал PRO не контролирует и не несет ответственность за сайты
        третьих лиц, на которые Пользователь может перейти по ссылкам, доступным
        на Сайте Водоканал PRO. На таких сайтах у Пользователя может собираться
        или запрашиваться иная персональная информация, а также могут
        совершаться иные действия.
      </div>,
      <div key={2} className={styles.row}>
        Водоканал PRO не проверяет достоверность персональной информации,
        предоставляемой Пользователями, и не осуществляет контроль за их
        дееспособностью. Однако Водоканал PRO исходит из того, что Пользователь
        предоставляет достоверную и достаточную персональную информацию по
        вопросам, предлагаемым в форме регистрации, что указанные им при
        регистрации персональные данные принадлежат лично ему и поддерживает эту
        информацию в актуальном состоянии. Риск предоставления недостоверной
        информации несет предоставивший ее Пользователь.
      </div>,
      <div key={3} className={styles.row}>
        Согласие Пользователя на обработку персональных данных действует
        бессрочно с момента осуществления регистрации Пользователя на сайте, и
        не требует периодического подтверждения. Водоканал PRO будет хранить
        персональную информацию столько времени, сколько это необходимо для
        достижения цели, для которой она была собрана, или для соблюдения
        требований законодательства и нормативных актов.
      </div>,
    ],
  },
];

export const PROCEDURE_OF_COLLECTING_PARAGRAPH_DATA = [
  {
    title:
      'Порядок сбора, хранения, передачи и других видов обработки персональных данных',
    id: 5,
    rows: [
      <div key={1} className={styles.row}>
        Водоканал PRO обеспечивает сохранность персональных данных и принимает
        все возможные меры, исключающие доступ к персональным данным
        неуполномоченных лиц.
      </div>,
      <div key={2} className={styles.row}>
        Персональные данные Пользователя никогда, ни при каких условиях не будут
        переданы третьим лицам, за исключением случаев, связанных с исполнением
        действующего законодательства.
      </div>,
      <div key={3} className={styles.row}>
        В случае выявления неточностей в персональных данных, Пользователь может
        актуализировать их, направив Водоканал PRO уведомление по Адресу для
        уведомлений, с пометкой «Актуализация персональных данных».
      </div>,
      <div key={4} className={styles.row}>
        Срок обработки персональных данных является неограниченным. Пользователь
        может в любой момент отозвать свое согласие на обработку персональных
        данных, направив Водоканал PRO уведомление с помощью электронной почты
        по Адресу для уведомлений, с пометкой «Отзыв согласия на обработку
        персональных данных».
      </div>,
    ],
  },
];

export const FINAL_PROVISIONS_PARAGRAPH_DATA = [
  {
    title: 'Заключительные положения',
    id: 6,
    rows: [
      <div key={1} className={styles.row}>
        Водоканал PRO имеет право вносить изменения в настоящую Политику. При
        внесении изменений в актуальной редакции указывается дата последнего
        обновления. Новая редакция Политики вступает в силу с момента ее
        опубликования на Сайте, если иное не предусмотрено новой редакцией
        Политики. Действующая редакция всегда находится и доступна на настоящей
        странице Сайта.
      </div>,
      <div key={2} className={styles.row}>
        К настоящей Политике и отношениям между пользователем и Водоканал PRO,
        возникающим в связи с применением Политики, подлежит применению право
        Российской Федерации.
      </div>,
      <div key={3} className={styles.row}>
        При обработке персональных данных пользователей Водоканал PRO
        руководствуется Федеральным законом РФ «О персональных данных».
      </div>,
      <div key={4} className={styles.row}>
        Пользователь может получить любые разъяснения по интересующим вопросам,
        касающимся обработки его персональных данных, обратившись к Водоканал
        PRO с помощью электронной почты по Адресу для уведомлений.
      </div>,
      <div key={5} className={styles.row}>
        В данном документе будут отражены любые изменения политики обработки
        персональных данных Водоканал PRO. В случае существенных изменений
        Пользователю может быть выслана информация на указанный им электронный
        адрес.
      </div>,
      <div key={6} className={styles.row}>
        Адресами для уведомлений в рамках настоящей Политики являются:{' '}
        <button
          type="button"
          onClick={openMailApplication('info@vodokanal-pro.ru')}
          className={clsx(styles.link, styles.contents)}
        >
          info@vodokanal-pro.ru
        </button>
        , либо на юридический адрес Водоканал PRO 603000, Россия, г. Нижний
        Новгород, ул. Соревнования, д. 3
      </div>,
    ],
  },
];
