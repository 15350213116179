import clsx from 'clsx';
import styles from './GlassCard.module.scss';
import { GlassCardPropsType } from './types';

function GlassCard({ translate }: GlassCardPropsType) {
  return (
    <div className={styles.border}>
      <div
        className={clsx(styles.card, {
          [styles.card__top_left]: translate === 'TOP_LEFT',
          [styles.card__top_right]: translate === 'TOP_RIGHT',
          [styles.card__bottom_left]: translate === 'BOTTOM_LEFT',
          [styles.card__bottom_right]: translate === 'BOTTOM_RIGHT',
        })}
      >
        <div className={styles.card_img} />
      </div>
    </div>
  );
}

export default GlassCard;
