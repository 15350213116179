import styles from '../../Assets/styles/textFields.module.scss';

export function createRowText(
  rows: JSX.Element[],
  id: number,
  isFirstNumerical?: boolean,
) {
  return rows.map((text, idx) => {
    let countRow = 0;
    let isShow = false;
    if (isFirstNumerical && idx !== 0) {
      countRow = idx;
    } else {
      countRow = idx + 1;
    }
    if (isFirstNumerical && idx === 0) {
      isShow = false;
    } else {
      isShow = true;
    }

    return (
      <div key={Math.random()} className={styles.flex}>
        {isShow && <span className={styles.bold}>{`${id}.${countRow}. `}</span>}
        {text}
      </div>
    );
  });
}

export function createParagraph(
  arr: {
    title: string;
    id: number;
    rows: JSX.Element[];
  }[],
  isFirstNumerical?: boolean,
) {
  return arr.map((paragraph) => {
    return (
      <div key={paragraph.id} className={styles.paragraph}>
        <h3
          className={styles.title}
        >{`${paragraph.id}. ${paragraph.title}`}</h3>
        <div className={styles.paragraph_list}>
          {createRowText(paragraph.rows, paragraph.id, isFirstNumerical)}
        </div>
      </div>
    );
  });
}

export function createTextField(arr: JSX.Element[]) {
  return arr.map((text) => text);
}
