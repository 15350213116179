import React, { useEffect, useState } from 'react';
import { clsx } from 'clsx';

import Icon from '../Icon';
import { IconsNames } from '../Icon/constants';

import styles from './PopupButton.module.scss';

const COLOR_CHANGE_POINT = 1700;

function PopupButton() {
  const [isWhite, setIsWhite] = useState<boolean>(false);

  const handleButtonClick = () => {
    window.scrollTo({ behavior: 'smooth', top: 0 });
  };

  const handleScroll = () => {
    if (
      document.documentElement.scrollHeight - window.scrollY <=
      COLOR_CHANGE_POINT
    ) {
      setIsWhite(true);
    } else {
      setIsWhite(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <button
      type="button"
      className={styles.popup_button}
      onClick={handleButtonClick}
    >
      <Icon
        name={IconsNames.ArrowUp}
        className={clsx(styles.popup_button_icon, {
          [styles.icon_white]: isWhite,
        })}
      />
      <Icon
        name={IconsNames.GradientCircle}
        className={styles.popup_button_outline}
      />
    </button>
  );
}

export default PopupButton;
