import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import styles from '../../Assets/styles/textFields.module.scss';

export const USER_AGREEMENT_TEXT = [
  <p className={styles.mb_15} key={1}>
    Настоящее Соглашение определяет условия использования Вами («Пользователь»)
    сайта{' '}
    <a
      className={clsx(styles.link, styles.contents)}
      href="https://vodokanal-pro.ru"
    >
      www.vodokanal-pro.ru
    </a>{' '}
    , а также всех материалов, опубликованных на нём (далее — «Сайт»).
  </p>,
  <p className={styles.mb_15} key={2}>
    Сайт принадлежит и администрирует компанией ООО
    &ldquo;Аутомэйт-бизнес&rdquo;, ИНН 5260477404, Юридический адрес 603000, г.
    Нижний Новгород, ул. Соревнования, 3, П.1 («Водоканал PRO»).
  </p>,
  <p className={styles.mb_15} key={3}>
    Осуществляя фактическое использование Сайта Пользователь подтверждает, что
    ознакомлен со всеми пунктами настоящего Соглашения и безусловно принимает
    их.
  </p>,
];

export const GENERAL_PROVISIONS_PAGE_PARAGRAPH_DATA = [
  {
    title: 'Общие положения',
    id: 1,
    rows: [
      <div key={1} className={styles.row}>
        Использование материалов и сервисов Сайта регулируется нормами
        действующего законодательства Российской Федерации.
      </div>,
      <div key={2} className={styles.row}>
        Настоящее Соглашение является публичной офертой. Получая доступ к
        материалам Сайта Пользователь считается присоединившимся к настоящему
        Соглашению.
      </div>,
      <div key={3} className={styles.row}>
        Водоканал PRO вправе в любое время в одностороннем порядке изменять
        условия настоящего Соглашения. Такие изменения вступают в силу по
        истечении 3 (Трех) дней с момента размещения новой версии Соглашения на
        сайте. При несогласии Пользователя с внесенными изменениями он обязан
        отказаться от доступа к Сайту, прекратить использование материалов и
        сервисов Сайта.
      </div>,
    ],
  },
];

export const USER_MUST_PARAGRAPH_DATA = [
  {
    title: 'Обязательства Пользователя',
    id: 2,
    rows: [
      <div key={1} className={styles.row}>
        Пользователь соглашается не предпринимать действий, которые могут
        рассматриваться как нарушающие российское законодательство или нормы
        международного права, в том числе в сфере интеллектуальной
        собственности, авторских и/или смежных правах, а также любых действий,
        которые приводят или могут привести к нарушению нормальной работы Сайта
        и сервисов Сайта.
      </div>,
      <div key={2} className={styles.row}>
        Использование материалов Сайта без согласия правообладателей не
        допускается (статья 1270 Г.К РФ). Для правомерного использования
        материалов Сайта необходимо заключение лицензионных договоров (получение
        лицензий) от Правообладателей.
      </div>,
      <div key={3} className={styles.row}>
        При цитировании материалов Сайта, включая охраняемые авторские
        произведения, ссылка на Сайт обязательна (подпункт 1 пункта 1 статьи
        1274 Г.К РФ).
      </div>,
      <div key={4} className={styles.row}>
        Комментарии и иные записи Пользователя на Сайте не должны вступать в
        противоречие с требованиями законодательства Российской Федерации и
        общепринятых норм морали и нравственности.
      </div>,
      <div key={5} className={styles.row}>
        Пользователь предупрежден о том, что Водоканал PRO не несет
        ответственности за посещение и использование им внешних ресурсов, ссылки
        на которые могут содержаться на сайте.
      </div>,
      <div key={6} className={styles.row}>
        Пользователь согласен с тем, что Водоканал PRO не несет ответственности
        и не имеет прямых или косвенных обязательств перед Пользователем в связи
        с любыми возможными или возникшими потерями или убытками, связанными с
        любым содержанием Сайта, регистрацией авторских прав и сведениями о
        такой регистрации, товарами или услугами, доступными на или полученными
        через внешние сайты или ресурсы либо иные контакты Пользователя, в
        которые он вступил, используя размещенную на Сайте информацию или ссылки
        на внешние ресурсы.
      </div>,
      <div key={7} className={styles.row}>
        Пользователь принимает положение о том, что все материалы и сервисы
        Сайта или любая их часть могут сопровождаться рекламой. Пользователь
        согласен с тем, что Водоканал PRO не несет какой-либо ответственности и
        не имеет каких-либо обязательств в связи с такой рекламой.
      </div>,
    ],
  },
];

export const ANOTHER_PARAGRAPH_DATA = [
  {
    title: 'Прочие условия',
    id: 3,
    rows: [
      <div key={1} className={styles.row}>
        Неотъемлемой частью настоящего Соглашения является Политика
        конфиденциальности и защиты персональных данных, размещённая по ссылке{' '}
        <NavLink className={clsx(styles.link, styles.contents)} to="/privacy">
          www.vodokanal-pro.ru/privacy
        </NavLink>
      </div>,
      <div key={2} className={styles.row}>
        Все возможные споры, вытекающие из настоящего Соглашения или связанные с
        ним, подлежат разрешению в соответствии с действующим законодательством
        Российской Федерации и рассматриваются исключительно в судах по месту
        нахождения Водоканал PRO.
      </div>,
      <div key={3} className={styles.row}>
        Ничто в Соглашении не может пониматься как установление между
        Пользователем и Водоканал PRO агентских отношений, отношений
        товарищества, отношений по совместной деятельности, отношений личного
        найма, либо каких-то иных отношений, прямо не предусмотренных
        Соглашением.
      </div>,
      <div key={4} className={styles.row}>
        Признание судом какого-либо положения Соглашения недействительным или не
        подлежащим принудительному исполнению не влечет недействительности иных
        положений Соглашения.
      </div>,
      <div key={5} className={styles.row}>
        Бездействие со стороны Водоканал PRO в случае нарушения кем-либо из
        Пользователей положений Соглашения не лишает Водоканал PRO права
        предпринять позднее соответствующие действия в защиту своих интересов и
        защиту авторских прав на охраняемые в соответствии с законодательством
        материалы Сайта.
      </div>,
    ],
  },
];
