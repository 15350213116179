import React from 'react';
import clsx from 'clsx';

import { ButtonProps } from './Button.interface';

import styles from './Button.module.scss';

function Button(props: ButtonProps) {
  const { isTransparent, className, onClick, children } = props;
  return (
    <button
      type="button"
      onClick={onClick}
      className={clsx(className, styles.button, {
        [styles.button_transparent]: isTransparent,
      })}
    >
      {children}
    </button>
  );
}

export default Button;
