import { Navigate } from 'react-router-dom';

import {
  EducationPage,
  LicenseAgreementPage,
  MainPage,
  PrivacyPolicyPage,
  TechnicalSupportPage,
  TermsPage,
  UserAgreementPage,
} from '../Pages';

export const arrRoutes = [
  {
    displayName: 'Навигация',
    key: 'navigate',
    element: <Navigate to="/main" />,
    path: '/',
  },
  {
    displayName: 'Главная',
    key: 'main',
    element: <MainPage />,
    path: 'main',
  },
  {
    displayName: 'Условия использования',
    key: 'terms',
    element: <TermsPage />,
    path: 'terms',
  },
  {
    displayName: 'Обучающие материалы',
    key: 'education',
    element: <EducationPage />,
    path: 'education',
  },
  {
    displayName: 'Лицензионное соглашение',
    key: 'license',
    element: <LicenseAgreementPage />,
    path: 'license',
  },
  {
    displayName: 'Политика конфиденциальности',
    key: 'privacy',
    element: <PrivacyPolicyPage />,
    path: 'privacy',
  },
  {
    displayName: 'Пользовательское соглашение',
    key: 'user_agreement',
    element: <UserAgreementPage />,
    path: 'user_agreement',
  },
  {
    displayName: 'Регламент технической поддержки',
    key: 'technical_support',
    element: <TechnicalSupportPage />,
    path: 'technical_support',
  },
];
