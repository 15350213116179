import clsx from 'clsx';
import styles from './Table.module.scss';

function Table({ arr, double }: { arr: string[][]; double?: any }) {
  function createTable() {
    return arr.map((row, index) => {
      return (
        <div
          key={row[index]}
          className={clsx(styles.grid_row, {
            [styles.grid_row_double]: double,
          })}
        >
          {row.map((cell) => {
            return (
              <div key={cell} className={styles.cell}>
                {cell}
              </div>
            );
          })}
        </div>
      );
    });
  }
  return <>{createTable()}</>;
}

export default Table;
