import { useEffect, useRef } from 'react';
import LocomotiveScroll from 'locomotive-scroll';

export function useLocomotiveScroll() {
  const scrollRef = useRef(null);
  window.scrollTo(0, 0);
  return useEffect(() => {
    if (scrollRef.current == null) {
      scrollRef.current = new LocomotiveScroll({ autoStart: false });
      scrollRef.current.start();
    }
    return () => {
      if (
        scrollRef.current !== null &&
        scrollRef.current.coreInstance !== undefined
      ) {
        scrollRef.current?.destroy();
      }
    };
  }, []);
}
