import { NavLink } from 'react-router-dom';
import { openMailApplication } from '../../Utils/Functions/openMailApplication';
import { TechMailIcon } from '../../Utils/SvgComponents';
import ButtonWithBubbles from '../ButtonWithBubbles';
import Table from '../Table';

import styles from './TechnicalSupprotSection.module.scss';
import { TECH_TABLE_CELLS } from './techTableCells';

function TechnicalSupprotSection() {
  return (
    <section className={styles.container}>
      <div className={styles.tech_block}>
        <h2 className={styles.title}>Техподдержка</h2>
        <p className={styles.descr}>
          Устранение неисправностей и усовершенствование ПО осуществляется
          специалистами Automate-Business. Наши эксперты ответят на любое
          количество обращений и придут на помощь по телефону или на портале
          техподдержки в удобное вам время
        </p>
        <div className={styles.connection_row}>
          <div className={styles.connect_item}>
            <TechMailIcon />
            <button
              type="button"
              onClick={openMailApplication('info@vodokanal-pro.ru')}
              className={styles.connect_link}
            >
              info@vodokanal-pro.ru
            </button>
          </div>
          <NavLink to="/technical_support">
            <ButtonWithBubbles
              onClick={() => {}}
              text="Регламент техподдержки"
            />
          </NavLink>
        </div>
      </div>
      <div className={styles.scheme_block}>
        <h2 className={styles.title}>Схема обслуживания</h2>
        <div className={styles.glass_card}>
          <div className={styles.overlay} />
          <div className={styles.table}>
            <Table arr={TECH_TABLE_CELLS} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default TechnicalSupprotSection;
