import clsx from 'clsx';
import styles from '../../Assets/styles/textFields.module.scss';
import { TECH_TABLE_CELLS } from '../../Components/TechnicalSupprotSection/techTableCells';
import { Table } from '../../Components';
import { openMailApplication } from '../../Utils/Functions/openMailApplication';

const LIFE_CYCLE_CELLS = [
  ['Стадия жизненного цикла Версии Платформы', 'Характер поддержки'],
  [
    'Beta (до момента начала продаж)',
    'Консультации по эксплуатации работоспособность Платформы в целом или какого-либо из ее модулей',
  ],
  [
    'Коммерческая (с момента начала продаж)',
    'Полный состав услуг Технической поддержки',
  ],
  [
    'Коммерческая (завершены продажи, объявлен срок завершения жизненного цикла Версии Платформы (EOL))',
    'Консультации по эксплуатации и устранение критичных ошибок (выпуск специальных Сборок Платформы, устраняющих критические ошибки)',
  ],
  [
    'Коммерческая (наступил срок завершения жизненного цикла Версии Платформы (EOL))',
    'Техническая поддержка не предоставляется',
  ],
];

export const TERMS_PARAGRAPH_DATA = [
  {
    title: 'Термины и сокращения',
    id: 1,
    rows: [
      <div key={1} className={styles.row}>
        <span className={styles.bold}>Платформа -</span> программа для ЭВМ
        «Водоканал-PRO», право на получение Технической поддержки которой
        подтверждается правомерно приобретенным правом на экземпляр программы
        для ЭВМ «Водоканал-PRO».
      </div>,
      <div key={2} className={styles.row}>
        <span className={styles.bold}>Версия Платформы -</span> фиксированное по
        реализуемым функциональным возможностям состояние Платформы,
        обозначенное в наименовании Платформы двумя первыми группами цифр,
        разделёнными точками. Пример: 1.0.0.
      </div>,
      <div key={3} className={styles.row}>
        <span className={styles.bold}>Сборка Платформы -</span> фиксированное
        состояние программного кода Платформы, обозначенное в наименовании
        Платформы цифробуквенным кодом, следующим перед Версией Платформы.
        Пример: 01.1.0.0.
      </div>,
      <div key={4} className={styles.row}>
        <span className={styles.bold}>Правообладатель -</span> Общество с
        ограниченной ответственностью «АУТОМЭЙТ-БИЗНЕС», владеющее
        исключительным правом на Платформу.
      </div>,
      <div key={5} className={styles.row}>
        <span className={styles.bold}>Пользователь -</span> физическое лицо,
        представляющее интересы и выступающее от имени юридического лица,
        обладателя лицензии на Техническую поддержку Платформы.
      </div>,
      <div key={6} className={styles.row}>
        <span className={styles.bold}>
          Идентифицирующая Пользователя информация -
        </span>{' '}
        фамилия, имя и отчество Пользователя; наименование юридического лица, от
        имени которого выступает Пользователь.
      </div>,
      <div key={7} className={styles.row}>
        <span className={styles.bold}>Техническая поддержка -</span> содействие
        Пользователю в использовании Платформы, оказываемое в соответствии с
        настоящим Регламентом технической поддержки (далее – Регламент)
        выделенным для этих целей подразделением Правообладателя (далее — Служба
        технической поддержки), предоставление доступа к новым Версиям
        Платформы. Техническая поддержка предоставляется при соблюдении
        следующих условий:
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          документальное подтверждение наличия сбоя;
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          корректная эксплуатация программного обеспечения в соответствии с
          инструкцией;
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          отсутствие самостоятельного вмешательства Пользователя в программное
          обеспечение.
        </div>
      </div>,
      <div key={8} className={styles.row}>
        <span className={styles.bold}>
          Электронный адрес для обращений в Службу технической поддержки -
        </span>{' '}
        <button
          type="button"
          onClick={openMailApplication('support@vodokanal-pro.ru')}
          className={clsx(styles.link, styles.contents)}
        >
          support@vodokanal-pro.ru
        </button>
      </div>,
      <div key={9} className={styles.row}>
        <span className={styles.bold}>Документация -</span> текущая документация
        на Платформу в печатной или электронной форме, которая меняется в
        зависимости от версии Платформы.
      </div>,
      <div key={10} className={styles.row}>
        <span className={styles.bold}>Завершение жизненного цикла (EOL) -</span>{' '}
        момент времени, с которого прекращается Поддержка Версии Платформы в
        связи с её устареванием.
      </div>,
      <div key={11} className={styles.row}>
        <span className={styles.bold}>Нормальная работа Платформы -</span>{' '}
        состояние, соответствующее заявленным в технической документации для
        Версии Платформы параметрам и функциям.
      </div>,
      <div key={12} className={styles.row}>
        <span className={styles.bold}>Заявка -</span> письменное обращение
        Пользователя, отправленное на{' '}
        <button
          type="button"
          onClick={openMailApplication('support@vodokanal-pro.ru')}
          className={clsx(styles.link, styles.contents)}
        >
          support@vodokanal-pro.ru
        </button>
        , связанное с эксплуатацией Платформы и информирующее об Инциденте.
      </div>,
      <div key={13} className={styles.row}>
        <span className={styles.bold}>Инцидент -</span> событие, которое привело
        или может привести к нарушению нормальной работы Платформы. По своему
        характеру Инциденты делятся на Запрос и Ошибка.
      </div>,
      <div key={14} className={styles.row}>
        <span className={styles.bold}>Запрос -</span> обращение в ситуации
        Нормальной работы Платформы, консультация о возможностях и способах
        выполнения стандартных операций эксплуатации и настройки Платформы,
        запросы на реализацию Изменений в Платформе, обусловленные реальными
        сценариями эксплуатации.
      </div>,
      <div key={15} className={styles.row}>
        <span className={styles.bold}>Ошибка -</span> неспособность Платформы к
        Нормальной работе. Означает любое поддающееся проверке и воспроизводимое
        несоответствие Платформы требованиям Документации.
      </div>,
      <div key={16} className={styles.row}>
        <span className={styles.bold}>Подтверждённая ошибка -</span>{' '}
        воспроизводимая Ошибка, которая может быть повторена и
        продемонстрирована Службе технической поддержки на экземпляре Платформы
        той же версии и сборки, что и у Пользователя, с использованием копии
        данных Пользователя или Эталонного набора данных.
      </div>,
      <div key={17} className={styles.row}>
        <span className={styles.bold}>Критичная ошибка -</span> Ошибка,
        приводящая к невозможности использования Платформы Пользователем (полное
        или частичное прекращение выполнения основных функций), либо к
        существенным ухудшениям, выражающимся в Недостаточной производительности
        Платформы.
      </div>,
      <div key={18} className={styles.row}>
        <span className={styles.bold}>Некритичная ошибка -</span> Ошибка,
        приводящая к временно допустимым ограничениям в использовании Платформы
        или допускающая возможность альтернативных способов реализации
        необходимого функционала Платформы.
      </div>,
      <div key={19} className={styles.row}>
        <span className={styles.bold}>Изменение -</span> необходимая адаптация
        Платформы к реальным условиям эксплуатации.
      </div>,
      <div key={20} className={styles.row}>
        <span className={styles.bold}>Время реакции -</span> период времени в
        рабочих часах от получения исполнителем заявки до информирования
        Пользователя о принятии заявки, что подтверждается ответным электронным
        сообщением по электронной почте или звонком по телефону, содержащим
        оповещение о регистрации и номере Заявки.
      </div>,
      <div key={21} className={styles.row}>
        <span className={styles.bold}>Срок выполнения -</span> рабочее время с
        момента регистрации Инцидента, в течение которого Службой технической
        поддержки предоставляются соответствующие услуги (устранение Ошибки или
        предоставление альтернативных способов реализации необходимого
        функционала, предоставление необходимой информации и оказание
        консультативной помощи). В нормативном времени разрешения Инцидента не
        учитывается время, затрачиваемое Пользователем на предоставление
        информации, необходимой для диагностики и на выполнение им действий,
        рекомендованных Службой технической поддержки.
      </div>,
      <div key={22} className={styles.row}>
        <span className={styles.bold}>Решение Инцидента -</span> предоставление
        информации, позволяющей восстановить нормальную работу Платформы. Это
        может быть исчерпывающий и понятный ответ на заданный вопрос,
        разъяснение деталей функционирования, настройки и выполнение операций в
        Платформе, рекомендации, позволяющие устранить сбой, включая
        предоставление «обходного пути» (work-around).
      </div>,
      <div key={23} className={styles.row}>
        <span className={styles.bold}>Обновление -</span> любые улучшения,
        модификации или исправления ошибок, доступные в соответствии с политикой
        управления релизами для Платформы, которые ООО «АУТОМЭЙТ-БИЗНЕС» делает
        общедоступными для своих клиентов в рамках Технического обслуживания.
      </div>,
      <div key={24} className={styles.row}>
        <span className={styles.bold}>Рабочий день -</span> промежуток времени с
        9:00 до 18:00 по московскому времени, за исключением выходных и
        праздничных дней, согласно законодательству Российской Федерации.
      </div>,
      <div key={25} className={styles.row}>
        <span className={styles.bold}>Рабочий час -</span> астрономический час в
        пределах Рабочего дня.
      </div>,
    ],
  },
];

export const GEN_PROVISIONS_PARAGRAPH_DATA = [
  {
    title: 'Общие положения',
    id: 2,
    rows: [
      <div key={1} className={styles.row}>
        Настоящий Регламент представляет собой в одностороннем порядке
        декларируемый ООО «АУТОМЭЙТ-БИЗНЕС» порядок оказания услуг Технической
        поддержки программы для ЭВМ «Водоканал-PRO» (далее — Платформа) Службой
        технической поддержки.
      </div>,
      <div key={2} className={styles.row}>
        Пользователь считается принявшим и безоговорочно согласным с условиями
        настоящего Регламента с момента приобретения права на поддержку для ЭВМ
        «Водоканал-PRO»
      </div>,
      <div key={3} className={styles.row}>
        Настоящий Регламент размещен на сайте по адресу
        https://www.vodokanal-pro.ru
      </div>,
      <div key={4} className={styles.row}>
        Настоящий Регламент, равно как и любая из его частей, может быть изменен
        ООО «АУТОМЭЙТ-БИЗНЕС» путем размещения новой редакции на сайте
        Правообладателя по адресу, указанному в п. 2.3 настоящего Регламента.
      </div>,
    ],
  },
];

export const SERVICES_PARAGRAPH_DATA = [
  {
    title: 'Состав услуг',
    id: 3,
    rows: [
      <div key={1} className={styles.row}>
        ООО «АУТОМЭЙТ-БИЗНЕС». придерживается следующих уровней модели
        Технической поддержки:
      </div>,
      <div key={2} className={styles.row}>
        <span className={styles.bold}>Уровень 1.</span> <br />
        На данном уровне Технической поддержки отсутствует прямое привлечение
        специалистов ООО «АУТОМЭЙТ-БИЗНЕС». Решение Инцидентов решается
        внутренней службой Технической или функциональной поддержки Заказчика.
      </div>,
      <div key={3} className={styles.row}>
        <span className={styles.bold}>Уровень 2.</span> <br />
        Данный уровень Технической поддержки подразумевает гарантийную поддержку
        ООО «АУТОМЭЙТ-БИЗНЕС» /Правообладателя (поддержка вендора), которая
        действует в течение 12 (двенадцати) месяцев с даты покупки права на
        поддержку для ЭВМ «Водоканал-PRO» . По истечении указанного срока для
        получения Технической поддержки необходимо ежегодно приобретать лицензию
        на право получения услуг технической поддержки. Поддержка вендора
        включает:
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Обращение компании Заказчика на{' '}
          <button
            type="button"
            onClick={openMailApplication('support@vodokanal-pro.ru')}
            className={clsx(styles.link, styles.contents)}
          >
            support@vodokanal-pro.ru
          </button>
          Правила создания Обращений описаны в разделе 5 настоящего Регламента.
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Исправления заведённых и подтверждённых Ошибок Платформы, сроки и
          реализация зависят от важности Заявок.
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Доступ к рассылке, содержащей информацию об обновлениях Платформы и
          новом функционале.
        </div>
      </div>,
    ],
  },
];

export const HOW_TO_REQUEST_PARAGRAPH_DATA = [
  {
    title: 'Порядок обращения за технической поддержкой',
    id: 4,
    rows: [
      <div key={1} className={styles.row}>
        Пользователь обращается в Службу технической поддержки путем направления
        Обращения с указанием идентифицирующей Пользователя информации и
        описанием возникшей проблемы следующим способом: Регистрация Обращения
        на{' '}
        <button
          type="button"
          onClick={openMailApplication('support@vodokanal-pro.ru')}
          className={clsx(styles.link, styles.contents)}
        >
          support@vodokanal-pro.ru
        </button>{' '}
        Обращение должно содержать всю необходимую информацию по существу
        запроса согласно разделу 5 настоящего Регламента.
      </div>,
      <div key={2} className={styles.row}>
        В случае отказа Пользователя сообщить идентифицирующую его информацию,
        Сотрудник Службы технический поддержки имеет право не оказывать такому
        Пользователю услуг по Технической поддержке.
      </div>,
      <div key={3} className={styles.row}>
        Обращения в Службу технической поддержки регистрируются в виде Заявки с
        присвоением уникального номера. Подтверждением регистрации Обращения для
        его инициатора служит номер Заявки, передаваемый техническими средствами
        портала Службы технической поддержки. Указание Пользователем номера
        исходной Заявки при повторных обращениях позволяет сотрудникам Службы
        технической поддержки оперативно вникнуть в контекст конкретного
        Обращения.
      </div>,
      <div key={4} className={styles.row}>
        Пользователь принимает на себя обязанность своевременного и
        квалифицированного взаимодействия со Службой технической поддержки в
        соответствии с настоящим Регламентом. При необходимости он
        самостоятельно информирует других Пользователей, действующих в интересах
        того же юридического лица, о статусе Обращения или перепоручает им
        работу с Обращением, уведомив об этом Службу технической поддержки
        Правообладателя.
      </div>,
      <div key={5} className={styles.row}>
        Для ускорения решения Инцидента может использоваться почта, телефон и
        другие средства связи.
      </div>,
      <div key={6} className={styles.row}>
        При регистрации Обращения Пользователь устанавливает важность обращения
        согласно таблице:
        <Table arr={TECH_TABLE_CELLS} />
      </div>,
      <div key={7} className={styles.row}>
        Важность обращения устанавливается Пользователем, основываясь на
        срочности и важности проблемы для Пользователя, на степени влияния
        проблемы на технологические и бизнес-процессы Пользователя.
      </div>,
      <div key={8} className={styles.row}>
        В процессе работы с Обращением важность может быть изменена, это
        изменение должно быть согласовано и Пользователем, и сотрудником Службы
        технической поддержки, который работает с этим Обращением.
      </div>,
    ],
  },
];

export const QUEUE_PARAGRAPH_DATA = [
  {
    title: 'Порядок создания обращения',
    id: 5,
    rows: [
      <div key={1} className={styles.row}>
        Обращение в Службу технической поддержки осуществляется с адреса
        электронной почты домена юридического лица (организации) и должно
        содержать всю необходимую информацию по существу Запроса. При
        регистрации Обращения Пользователь должен указать:
      </div>,
      <div key={2} className={styles.row}>
        Краткое название Обращения.
      </div>,
      <div key={3} className={styles.row}>
        Название организации.
      </div>,
      <div key={4} className={styles.row}>
        Ф.И.О. контактного лица, должность, телефон для обратной связи.
      </div>,
      <div key={5} className={styles.row}>
        Общее описание проблемы:
        <div className={styles.flex_dot}>
          <span className={styles.dot} />В чем проявляется проблема?
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Какая последовательность действий приводит к воспроизведению проблемы?
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Как часто проявляется указанная проблема?
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Какие изменения в инфраструктуре или настройках ПО производили в
          последнее время?
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Описание и текст Ошибки.
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Какие затронуты функции Платформы.
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Скриншот или видео воспроизведения Ошибки.
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Любую другую специфическую информацию, необходимую для решения
          проблемы.
        </div>
      </div>,
      <div key={6} className={styles.row}>
        Выполнить необходимые в сложившейся ситуации действия, а именно:
        изменение настроек Платформы и/или условий её эксплуатации,
        корректировку сценариев работы с Платформой, установку и тестирование
        специально подготовленной Сборки Платформы, и другие подобные действия.
      </div>,
      <div key={7} className={styles.row}>
        Обязанностью Пользователя является оперативное информирование других
        Пользователей, действующих от лица одного юридического лица, о статусе
        Обращения, своевременное рассмотрение предложенного решения и
        организация выполнения указанных в нём действий силами Пользователя, а
        также сбор и предоставление необходимой дополнительной информации с
        учетом действующих внутренних правил и регламентов. Результат
        рассмотрения и применения решения по Обращению должен быть доведен до
        Службы технической поддержки с целью завершения работ по Обращению, либо
        для продолжения работ, исходя из достигнутого результата.
      </div>,
      <div key={8} className={styles.row}>
        Время ожидания ответа от Пользователя по рассмотрению и применению
        предложенного решения не учитывается в составе срока реагирования Службы
        технической поддержки на Обращение соответственно уровню обслуживания.
      </div>,
      <div key={9} className={styles.row}>
        Решение может находиться в состоянии ожидания подтверждения
        Пользователем успешности его применения и оценки эффективности не более
        15 (Пятнадцати) календарных дней с момента подготовки Службой
        технической поддержки, после чего происходит автоматическое Закрытие
        Заявки по Обращению, и работы по нему считаются завершенными.
      </div>,
      <div key={10} className={styles.row}>
        При повторном Обращении по уже закрытой Заявке оформляется новая Заявка.
      </div>,
      <div key={11} className={styles.row}>
        Пользователь обеспечивает использование наиболее актуальной из
        предоставленных Правообладателем Сборок Платформы при наличии в них
        модификаций, относящихся к решениям по его Обращениям.
      </div>,
    ],
  },
];

export const PROCEDURE_FOR_PROVIDING_PARAGRAPH_DATA = [
  {
    title: 'Порядок и условия предоставления технической поддержки',
    id: 6,
    rows: [
      <div key={1} className={styles.row}>
        Заявки, признанные исполнителем ошибками Платформы, будут переданы в
        разработку.
      </div>,
      <div key={2} className={styles.row}>
        Пользователь обеспечивает использование наиболее актуальной из
        предоставленных Правообладателем Сборок Платформы при наличии в них
        модификаций, относящихся к решениям по его Обращениям.
      </div>,
      <div key={3} className={styles.row}>
        Исправления по заявкам с важностью «Критичная» и «Высокая» будут
        включены в ближайшее обновление ПО.
      </div>,
      <div key={4} className={styles.row}>
        Решение по включению исправлений заявок с важностью «Стандартная» и
        «Низкая» в ближайшее обновление ПО исполнитель оставляет за собой.
      </div>,
      <div key={5} className={styles.row}>
        Всем Пользователям, создавшим Заявку категории «Ошибка», поступит
        уведомление о решении их Заявки, версии ПО, в котором Заявка решена,
        дате выхода обновления ПО.
      </div>,
    ],
  },
];

export const LIFE_CYCLE_PARAGRAPH_DATA = [
  {
    title: 'Жизненный цикл Версий Платформы',
    id: 7,
    rows: [
      <div key={1} className={styles.row}>
        Характер Технической поддержки Версии Платформы меняется в зависимости
        от стадии жизненного цикла:
        <Table arr={LIFE_CYCLE_CELLS} double />
      </div>,
      <div key={2} className={styles.row}>
        Правообладатель оповещает о завершении жизненного цикла Версии Платформы
        (EOL) не менее чем за 1 (Один) год до его наступления.
      </div>,
    ],
  },
];

export const PRICE_PARAGRAPH_DATA = [
  {
    title: 'Стоимость услуг',
    id: 8,
    rows: [
      <div key={1} className={styles.row}>
        Услуги 2 уровня предоставляются на основании ежегодной оплаты
        Технической поддержки.
      </div>,
      <div key={2} className={styles.row}>
        Услуги по договору оказываются удаленно.
      </div>,
    ],
  },
];
