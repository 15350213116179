/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-extraneous-dependencies
import LocomotiveScroll from 'locomotive-scroll';
import { useEffect, useRef } from 'react';
import {
  AdvantagesSection,
  CloudSection,
  FeaturesSection,
  FeedbackSection,
} from '../../Components';

import Header from '../../Components/Header';
import styles from './MainPage.module.scss';
import { useLocomotiveScroll } from '../../Utils/Functions/useLocomotiveScroll';

function MainPage() {
  useLocomotiveScroll();

  return (
    <div className={styles.main}>
      <Header />
      <FeaturesSection />
      <AdvantagesSection />
      <CloudSection />
      <FeedbackSection />
    </div>
  );
}

export default MainPage;
