import { useState } from 'react';
import clsx from 'clsx';

import {
  CheckMarkIcon,
  CloseIcon,
  FormDocIcon,
} from '../../Utils/SvgComponents';
import ButtonWithBubbles from '../ButtonWithBubbles';
import styles from './MainForm.module.scss';
import { MainFormPropsType } from './types';

function MainForm({ hideForm }: MainFormPropsType) {
  const [isCheckboxActive, setIsCheckboxActive] = useState(false);
  const [isBtnClicked, setIsBtnClicked] = useState(false);

  const toggleCheckboxStateHandler = () => {
    setIsCheckboxActive(!isCheckboxActive);
  };

  const onBtnClickHandler = () => {
    setIsBtnClicked(true);
  };

  const hideFormHandler = () => {
    hideForm();
    setIsBtnClicked(false);
  };
  return (
    <div className={styles.glass}>
      <form className={styles.form}>
        <button
          type="button"
          onClick={hideFormHandler}
          className={styles.close}
        >
          <CloseIcon />
        </button>
        <h3 className={styles.form_title}>Напишите нам!</h3>
        <p className={styles.form_subtitle}>
          Оставьте свои контактные данные - и наш специалист свяжется с вами
        </p>
        <input className={styles.input} type="text" placeholder="Ваше имя" />
        <input
          className={styles.input}
          type="text"
          placeholder="Номер телефона"
        />
        <input className={styles.input} type="text" placeholder="Ваше имя" />
        <textarea className={styles.textarea} placeholder="Комментарий" />
        <div className={styles.checkbox_row}>
          <button
            type="button"
            onClick={toggleCheckboxStateHandler}
            className={clsx(styles.checkbox, {
              [styles.checkbox_active]: isCheckboxActive,
            })}
          >
            <CheckMarkIcon />
          </button>
          <p className={styles.form_text}>
            Отправляя заполненную форму, вы даете согласие на обработку
            персональных данных
          </p>
        </div>
        <ButtonWithBubbles
          className={styles.form_btn}
          text="Отправить"
          onClick={onBtnClickHandler}
        />
        <div
          className={clsx(styles.second_layout, {
            [styles.second_layout__active]: isBtnClicked,
          })}
        >
          <div className={styles.content}>
            <button
              type="button"
              onClick={hideFormHandler}
              className={styles.close}
            >
              <CloseIcon />
            </button>
            <FormDocIcon />
            <p className={styles.big_title}>Заявка отправлена!</p>
            <p className={styles.big_descr}>
              Наш специалист свяжется с вами в ближайшее возможное время
            </p>
            <ButtonWithBubbles text="Продолжить" onClick={hideFormHandler} />
          </div>
        </div>
      </form>
    </div>
  );
}

export default MainForm;
