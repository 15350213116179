import { useState } from 'react';
import { HiddenWrapper, SmallHeader } from '../../Components';

import styles from './TechnicalSupportPage.module.scss';
import { createParagraph } from '../../Utils/Functions/createTextFuncitions';
import {
  GEN_PROVISIONS_PARAGRAPH_DATA,
  HOW_TO_REQUEST_PARAGRAPH_DATA,
  LIFE_CYCLE_PARAGRAPH_DATA,
  PRICE_PARAGRAPH_DATA,
  PROCEDURE_FOR_PROVIDING_PARAGRAPH_DATA,
  QUEUE_PARAGRAPH_DATA,
  SERVICES_PARAGRAPH_DATA,
  TERMS_PARAGRAPH_DATA,
} from './TechnicalSupportTextData';
import { useLocomotiveScroll } from '../../Utils/Functions/useLocomotiveScroll';

function TechnicalSupportPage() {
  const [activeSection, setActiveSection] = useState(0);
  useLocomotiveScroll();
  return (
    <div className={styles.page}>
      <SmallHeader
        togglePage={setActiveSection}
        title="Регламент технической поддержки"
        pagesName={['']}
        activeSection={activeSection}
      />

      <HiddenWrapper active={activeSection === 0}>
        <div className={styles.container}>
          {createParagraph(TERMS_PARAGRAPH_DATA)}
          {createParagraph(GEN_PROVISIONS_PARAGRAPH_DATA)}
          {createParagraph(SERVICES_PARAGRAPH_DATA, true)}
          {createParagraph(HOW_TO_REQUEST_PARAGRAPH_DATA)}
          {createParagraph(QUEUE_PARAGRAPH_DATA)}
          {createParagraph(PROCEDURE_FOR_PROVIDING_PARAGRAPH_DATA)}
          {createParagraph(LIFE_CYCLE_PARAGRAPH_DATA)}
          {createParagraph(PRICE_PARAGRAPH_DATA)}
        </div>
      </HiddenWrapper>
    </div>
  );
}

export default TechnicalSupportPage;
