import React from 'react';
import clsx from 'clsx';

import { IconsNames } from '../Icon/constants';
import Icon from '../Icon/Icon';

import { ArrowDownProps } from './ArrowDown.interface';

import styles from './ArrowDown.module.scss';

function ArrowDown(props: ArrowDownProps) {
  const { className, onClick } = props;

  return (
    <button
      type="button"
      className={clsx(styles.arrow_down, className)}
      onClick={onClick}
    >
      <Icon name={IconsNames.ArrowDown} className={styles.arrow_down_icon} />
    </button>
  );
}

export default ArrowDown;
