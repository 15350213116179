import { useState } from 'react';
import { HiddenWrapper, SmallHeader } from '../../Components';

import styles from './UserAgreementPage.module.scss';
import {
  createParagraph,
  createTextField,
} from '../../Utils/Functions/createTextFuncitions';
import {
  ANOTHER_PARAGRAPH_DATA,
  GENERAL_PROVISIONS_PAGE_PARAGRAPH_DATA,
  USER_AGREEMENT_TEXT,
  USER_MUST_PARAGRAPH_DATA,
} from './UserTextData';
import { useLocomotiveScroll } from '../../Utils/Functions/useLocomotiveScroll';

function UserAgreementPage() {
  const [activeSection, setActiveSection] = useState(0);
  useLocomotiveScroll();
  return (
    <div className={styles.page}>
      <SmallHeader
        togglePage={setActiveSection}
        title="Пользовательское соглашение"
        pagesName={['']}
        activeSection={activeSection}
      />

      <HiddenWrapper active={activeSection === 0}>
        <div className={styles.container}>
          {createTextField(USER_AGREEMENT_TEXT)}
          {createParagraph(GENERAL_PROVISIONS_PAGE_PARAGRAPH_DATA)}
          {createParagraph(USER_MUST_PARAGRAPH_DATA)}
          {createParagraph(ANOTHER_PARAGRAPH_DATA, true)}
        </div>
      </HiddenWrapper>
    </div>
  );
}

export default UserAgreementPage;
