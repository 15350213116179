import clsx from 'clsx';
import { BubblesBg } from '../../Utils/SvgComponents';
import styles from './ButtonWithBubbles.module.scss';
import { ButtonWithBubblesPropsType } from './types';

function ButtonWithBubbles({
  onClick,
  className,
  text = 'Попробовать',
}: ButtonWithBubblesPropsType) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={clsx(styles.btn, className)}
    >
      <div className={styles.border} />
      <span className={styles.btn_text}>{text}</span>
      <div className={styles.btn_bg}>
        <BubblesBg />
      </div>
    </button>
  );
}

export default ButtonWithBubbles;
