import clsx from 'clsx';
import styles from './HiddenWrapper.module.scss';
import { HiddenWrapperPropsType } from './types';

function HiddenWrapper({ children, active }: HiddenWrapperPropsType) {
  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.wrapper_active]: active,
      })}
    >
      {children}
    </div>
  );
}

export default HiddenWrapper;
