import { useState } from 'react';
import { HiddenWrapper, SmallHeader } from '../../Components';

import styles from './LicenseAgreementPage.module.scss';
import {
  createParagraph,
  createTextField,
} from '../../Utils/Functions/createTextFuncitions';
import {
  ALLERT_PARAGRAPH_DATA,
  APPLICABLE_LAW_PARAGRAPH_DATA,
  CHECK_PO_PARAGRAPH_DATA,
  CIRCLE_OF_PEOPLE_PARAGRAPH_DATA,
  COPYRIGHT_PARAGRAPH_DATA,
  ENTIRE_AGREEMENT_PARAGRAPH_DATA,
  EXPERT_PO_PARAGRAPH_DATA,
  GENERAL_PROVISIOBS_PARAGRAPH_DATA,
  GUARANTEES_PARAGRAPH_DATA,
  INSTALL_PARAGRAPH_DATA,
  LANGUAGE_OF_AGREEMENTS_PARAGRAPH_DATA,
  LICENSE_AGREEMENT_TEXT,
  LICENSE_PARAGRAPH_DATA,
  LIMITATION_PARAGRAPH_DATA,
  MOMENT_OF_AGREEMENTS_PARAGRAPH_DATA,
  PROGRAM_PARAGRAPH_DATA,
  REJECT_PARAGRAPH_DATA,
  REST_OF_RIGHTS_PARAGRAPH_DATA,
  RIGHTS_RESERVED_PARAGRAPH_DATA,
  STOP_LICENSE_PARAGRAPH_DATA,
  THIRD_PEOPLE_PARAGRAPH_DATA,
  TRANSLATE_PO_PARAGRAPH_DATA,
} from './LicenseTextData';
import { useLocomotiveScroll } from '../../Utils/Functions/useLocomotiveScroll';

function LicenseAgreementPage() {
  const [activeSection, setActiveSection] = useState(0);
  useLocomotiveScroll();
  return (
    <div className={styles.page}>
      <SmallHeader
        togglePage={setActiveSection}
        title="Лицензионное соглашение"
        pagesName={['']}
        activeSection={activeSection}
      />

      <HiddenWrapper active={activeSection === 0}>
        <div className={styles.container}>
          {createTextField(LICENSE_AGREEMENT_TEXT)}
          {createParagraph(PROGRAM_PARAGRAPH_DATA, true)}
          {createParagraph(INSTALL_PARAGRAPH_DATA, true)}
          {createParagraph(LICENSE_PARAGRAPH_DATA, true)}
          {createParagraph(CIRCLE_OF_PEOPLE_PARAGRAPH_DATA, true)}
          {createParagraph(REST_OF_RIGHTS_PARAGRAPH_DATA, true)}
          {createParagraph(COPYRIGHT_PARAGRAPH_DATA, true)}
          {createParagraph(THIRD_PEOPLE_PARAGRAPH_DATA, true)}
          {createParagraph(RIGHTS_RESERVED_PARAGRAPH_DATA, true)}
          {createParagraph(LANGUAGE_OF_AGREEMENTS_PARAGRAPH_DATA, true)}
          {createParagraph(MOMENT_OF_AGREEMENTS_PARAGRAPH_DATA, true)}
          {createParagraph(STOP_LICENSE_PARAGRAPH_DATA, true)}
          {createParagraph(GUARANTEES_PARAGRAPH_DATA, true)}
          {createParagraph(REJECT_PARAGRAPH_DATA, true)}
          {createParagraph(LIMITATION_PARAGRAPH_DATA, true)}
          {createParagraph(TRANSLATE_PO_PARAGRAPH_DATA, true)}
          {createParagraph(CHECK_PO_PARAGRAPH_DATA, true)}
          {createParagraph(EXPERT_PO_PARAGRAPH_DATA, true)}
          {createParagraph(ALLERT_PARAGRAPH_DATA, true)}
          {createParagraph(APPLICABLE_LAW_PARAGRAPH_DATA, true)}
          {createParagraph(GENERAL_PROVISIOBS_PARAGRAPH_DATA, true)}
          {createParagraph(ENTIRE_AGREEMENT_PARAGRAPH_DATA, true)}
        </div>
      </HiddenWrapper>
    </div>
  );
}

export default LicenseAgreementPage;
