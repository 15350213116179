export const TECH_TABLE_CELLS = [
  ['Важность', 'Описание', 'Время реакции', 'Срок выполнения'],
  [
    'Критичная',
    'Отсутствует работоспособность Платформы в целом или какого-либо из ее модулей',
    'В течении 1 рабочего дня',
    'До 2 рабочих дней (Результат: решение, способ обхода или план дальнейших действий)',
  ],
  [
    'Высокая',
    'Инцидент препятствует использованию базовых функций Платформы в отдельном модуле. Работоспособность Платформы не нарушена',
    'В течении 1 рабочего дня',
    'До 3 рабочих дней (Результат: решение, способ обхода или план дальнейших действий)',
  ],
  [
    'Стандартная',
    'Инцидент не препятствует использованию базовых функций Платформы, ограничены действия в рамках базового функционала Платформы',
    'В течении 3 рабочих дней',
    'До 14 рабочих дней, в рамках релиза',
  ],
  [
    'Низкая',
    'Инцидент не препятствует использованию базовых функций и не ограничивает действий в Платформе',
    'В течении 5 рабочих дней',
    'До 14 рабочих дней, в рамках релиза',
  ],
];
