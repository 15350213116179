import { NavLink } from 'react-router-dom';
import ButtonWithBubbles from '../ButtonWithBubbles';
import styles from './CookiesFooter.module.scss';
import { CookiesFooterPropsType } from './types';

function CookiesFooter({ onClickHandler }: CookiesFooterPropsType) {
  const hideModal = () => {
    onClickHandler(false);
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.descr}>
        Наш веб-сайт использует cookies, чтобы улучшить ваш пользовательский
        опыт. Ознакомьтесь с нашей{' '}
        <NavLink
          onClick={hideModal}
          className={styles.link}
          to="/user_agreement"
        >
          Политикой конфиденциальности
        </NavLink>
        , чтобы узнать больше
      </p>
      <ButtonWithBubbles text="Принять" onClick={hideModal} />
    </div>
  );
}

export default CookiesFooter;
