import clsx from 'clsx';
import styles from './FeedbackSection.module.scss';
import { MailIcon, PhoneIcon } from '../../Utils/SvgComponents';
import Button from '../Button';
import { openMailApplication } from '../../Utils/Functions/openMailApplication';

function FeedbackSection() {
  return (
    <section id="Form" className={styles.container}>
      <div className={styles.grid_wrap}>
        <div className={styles.info_wrap}>
          <h2 className={styles.title_main}>
            Хотите обсудить продукт, заказать демо или у вас есть вопросы?
          </h2>
          <p className={styles.text}>
            Оставьте свои контактные данные - и наш специалист свяжется с вами
          </p>
          <p className={styles.text_small}>Другие способы связи:</p>
          <div className={styles.contacts_wrap}>
            <div className={styles.contact_item}>
              <PhoneIcon />
              <div className={clsx(styles.text, styles.numeric)}>
                +7 (831) 213-51-13
              </div>
            </div>
            <div className={styles.contact_item}>
              <MailIcon />
              <button
                type="button"
                onClick={openMailApplication('info@vodokanal-pro.ru')}
                className={clsx(styles.text, styles.numeric)}
              >
                info@vodokanal-pro.ru
              </button>
            </div>
          </div>
        </div>
        <form className={styles.form}>
          <h3 className={styles.form_title}>Напишите нам!</h3>
          <input className={styles.input} type="text" placeholder="Ваше имя" />
          <input
            className={styles.input}
            type="text"
            placeholder="Номер телефона"
          />
          <input className={styles.input} type="text" placeholder="Ваше имя" />
          <textarea className={styles.textarea} placeholder="Комментарий" />
          <div className={styles.checkbox_row}>
            <div className={styles.checkbox}>
              <div className={styles.ok} />
            </div>
            <p className={styles.form_text}>
              Отправляя заполненную форму, вы даете согласие на обработку
              персональных данных
            </p>
          </div>
          <Button className={styles.form_btn} onClick={() => {}} isTransparent>
            Отправить
          </Button>
        </form>
      </div>
    </section>
  );
}

export default FeedbackSection;
