import CardOfVideo from '../CardOfVideo';

import styles from './VideoSection.module.scss';

function VideoSection() {
  return (
    <div className={styles.container}>
      <CardOfVideo />
      <CardOfVideo />
      <CardOfVideo />
      <CardOfVideo />
      <CardOfVideo />
    </div>
  );
}

export default VideoSection;
