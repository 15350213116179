import clsx from 'clsx';
import Button from '../Button';
import styles from './SmallHeader.module.scss';
import { SmallHeaderPropsType } from './types';

function SmallHeader({
  togglePage,
  title,
  pagesName,
  activeSection,
}: SmallHeaderPropsType) {
  const toggleStateHandler = (num: number) => () => {
    togglePage(num);
  };
  function createLinksList() {
    return pagesName.map((item, index) => {
      return (
        <>
          <Button
            key={item}
            isTransparent
            onClick={toggleStateHandler(index)}
            className={clsx(styles.toggle_btn, {
              [styles.toggle_btn__active]: activeSection === index,
            })}
          >
            {item}
          </Button>
          {index !== pagesName.length - 1 && (
            <span className={styles.delimiter}> - </span>
          )}
        </>
      );
    });
  }
  return (
    <header className={styles.header}>
      <h2 className={styles.title}>{title}</h2>
      <div className={styles.btns_row}>{createLinksList()}</div>
    </header>
  );
}

export default SmallHeader;
