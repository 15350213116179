/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-extraneous-dependencies
import { Outlet } from 'react-router';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import styles from './MenuContainer.module.scss';
import TopNavBar from '../TopNavBar';
import Footer from '../Footer';
import PopupButton from '../PopupButton';
import Modal from '../Modal';
import CookiesFooter from '../CookiesFooter';
import MainForm from '../MainForm';

function MenuContainer() {
  const [isModalShow, setIsModalShow] = useState(false);
  const [isMainFormShow, setIsMainFormShow] = useState(false);

  const hideModal = () => {
    setIsModalShow(false);
  };

  const showModal = () => {
    setIsModalShow(true);
  };

  const hideForm = () => {
    setIsMainFormShow(false);
  };

  const showForm = () => {
    setIsMainFormShow(true);
  };

  useEffect(() => {
    const timer = setTimeout(showModal, 10000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={clsx(styles.root, {
        [styles.hidden]: isMainFormShow,
      })}
    >
      <TopNavBar showForm={showForm} />
      <Outlet />
      <Footer />
      <PopupButton />
      <Modal isShown={isModalShow}>
        <CookiesFooter onClickHandler={hideModal} />
      </Modal>
      <Modal isShown={isMainFormShow}>
        <MainForm hideForm={hideForm} />
      </Modal>
    </div>
  );
}

export default MenuContainer;
