import styles from './FeaturesSection.module.scss';
import { featuresItems } from './features';

function FeaturesSection() {
  function createFeaturesItems() {
    return featuresItems.map(({ icon, title, text }) => {
      return (
        <div className={styles.item} key={title}>
          {icon}
          <div className={styles.item_title}>{title}</div>
          <div className={styles.item_descr}>{text}</div>
        </div>
      );
    });
  }
  return (
    <section className={styles.container}>
      <span id="Features" className={styles.anchor} />
      <h3 className={styles.title_main}>Особенности</h3>
      <h4 className={styles.title}>
        Водоканал PRO разработан с учетом основных технологических требований и
        пожеланий к функционалу со стороны пользователей
      </h4>
      <div className={styles.feat_wrap}>{createFeaturesItems()}</div>
    </section>
  );
}

export default FeaturesSection;
