import { Route, Routes } from 'react-router-dom';
import { arrRoutes } from './Static/Routes';

import MenuContainer from './Components/MenuContainer';

function App() {
  return (
    <Routes>
      <Route path="/" element={<MenuContainer />}>
        {arrRoutes.map(({ path, element }) => (
          <Route path={path} element={element} key={path} />
        ))}
      </Route>
    </Routes>
  );
}

export default App;
