import {
  FeatAutomateLogo,
  FeatBrainLogo,
  FeatInfoLogo,
  FeatJustLogo,
  FeatProcessLogo,
  FeatReportLogo,
} from '../../Utils/SvgComponents';

export const featuresItems = [
  {
    icon: <FeatAutomateLogo />,
    title: 'Автоматизация обмена данными',
    text: 'между технологической службой и лабораторией, а также разграничение зон ответственности служб водоканала',
  },
  {
    icon: <FeatBrainLogo />,
    title: 'Модель искусственного интеллекта',
    text: 'подбирает оптимальные дозы реагентов с учётом изменения качества обрабатываемой воды и их стоимости, позволяя получить воду необходимого качества по наименьшей себестоимости',
  },
  {
    icon: <FeatJustLogo />,
    title: 'Простой и интуитивный',
    text: 'интерфейс продукта, позволяет специалистам быстро освоить весь функционал и начать работу в сервисе',
  },
  {
    icon: <FeatReportLogo />,
    title: 'Отчеты и выгрузки результатов',
    text: 'лабораторных исследований легко и удобно создавать с помощью встроенных сервисов',
  },
  {
    icon: <FeatProcessLogo />,
    title: 'Регламентированный процесс',
    text: 'регистрации, добавления реагентов, позволяющий отслеживать объёмы добавленных реагентов и итоговое качество воды',
  },
  {
    icon: <FeatInfoLogo />,
    title: 'Хранение информации',
    text: 'в одном месте, быстрый и удобный доступ к данным и их выгрузка для дальнейшего использования и анализа',
  },
];
