import clsx from 'clsx';
import styles from './Modal.module.scss';
import { ModalProps } from './types';

function Modal({ children, isShown }: ModalProps) {
  return (
    <div
      className={clsx(styles.modal, {
        [styles.active]: isShown,
        [styles.hide]: !isShown,
      })}
    >
      {children}
    </div>
  );
}

export default Modal;
