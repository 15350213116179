import styles from '../../Assets/styles/textFields.module.scss';

export const REGISTRATION_PARAGRAPH_DATA = [
  {
    title: 'Регистрация',
    id: 1,
    rows: [
      <div key={1} className={styles.row}>
        Перейдя по ссылке{' '}
        <a
          className={styles.link}
          href={process.env.REACT_APP_HOST_IP_REGISTER}
        >
          app.vodokanal-pro.ru/register
        </a>{' '}
        пользователь попадает на экран регистрации, где необходимо ввести
        Фамилию, Имя, Отчество, контактный телефон, адрес электронной почты,
        водоканал. Обязательные поля для заполнения обозначены `*`.
      </div>,
      <div key={2} className={styles.row}>
        Если все поля были заполнены верно, то после нажатия кнопки
        `Зарегистрироваться` появится соответствующее уведомление.
      </div>,
      <div key={3} className={styles.row}>
        На указанную почту придёт письмо с уведомлением о заявке на регистрацию
        нового пользователя.
      </div>,
      <div key={4} className={styles.row}>
        После того, как администратор водоканала рассмотрит заявку, на указанную
        почту придёт уведомление с решением о регистрации.
      </div>,
      <div key={5} className={styles.row}>
        После нажатия на кнопку в письме, переходим на новую страницу с
        открывшимся модальным окном и задаем пароль для аккаунта.
      </div>,
    ],
  },
];

export const AUTH_PARAGRAPH_DATA = [
  {
    title: 'Авторизация',
    id: 2,
    rows: [
      <div key={1} className={styles.row}>
        При переходе по ссылке{' '}
        <a className={styles.link} href={process.env.REACT_APP_HOST_IP}>
          app.vodokanal-pro.ru/login
        </a>{' '}
        мы попадаем на экран авторизации, где необходимо ввести логин (адрес
        электронной почты, указанный при регистрации) и пароль.
      </div>,
    ],
  },
];

export const APPLICATION_PARAGRAPH_DATA = [
  {
    title: 'Рассмотрение и принятие заявок на регистрацию нового пользователя',
    id: 3,
    rows: [
      <div key={1} className={styles.row}>
        В панели администратора в правой части экрана отображаются заявки на
        регистрацию новых пользователей. В каждой заявке содержится информация:
        <div className={styles.flex_dot}>
          <span className={styles.dot} /> номер заявки
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          ФИО
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          телефон
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          адрес электронной почты
        </div>
      </div>,
      <div key={2} className={styles.row}>
        Администратор водоканала на основании предоставленной информации
        принимает решение об одобрении заявки на регистрацию нажатием на одну из
        кнопок `Отклонить` или `Принять`.
      </div>,
      <div key={3} className={styles.row}>
        В случае принятия заявки в таблице пользователей водоканала появляется
        строка с новым пользователем, которому можно присвоить роли (лаборант,
        технолог или администратор). В случае, если ни одна роль не была
        присвоена, новому пользователю будет доступен только просмотр
        содержимого экранов `Лабораторные исследования`, `Мониторинг`,
        `Реагенты` и `Стандарты` до момента изменения его роли администратором.
      </div>,
    ],
  },
];

export const PASSWORD_PARAGRAPH_DATA = [
  {
    title: 'Смена пароля',
    id: 4,
    rows: [
      <div key={1} className={styles.row}>
        При нажатии на надпись `Забыли пароль?` открывается модальное окно, в
        котором необходимо ввести электронную почту для отправки ссылки на
        восстановление пароля.
      </div>,
      <div key={2} className={styles.row}>
        После нажатия на кнопку `Продолжить` появляется уведомление о созданной
        заявке на смену пароля.
      </div>,
      <div key={3} className={styles.row}>
        На указанную почту придет письмо с ссылкой на сброс пароля.
      </div>,
      <div key={4} className={styles.row}>
        После нажатия на кнопку в письме переходим на новую страницу с
        открывшимся модальным окном для ввода нового пароля.
      </div>,
      <div key={5} className={styles.row}>
        После сохранения появляется уведомление об успешном изменении пароля.
      </div>,
    ],
  },
];

export const LABORANT_PARAGRAPH_DATA = [
  {
    title: 'Лабораторные исследования: лаборант',
    id: 1,
    rows: [
      <div key={1} className={styles.row}>
        На данном экране содержится список всех лабораторных отчетов в
        хронологическом порядке. У каждого отчета есть:
        <div className={styles.flex_dot}>
          <span className={styles.dot} /> Номер
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Дата и время создания
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Тип (питьевой, поверхностный или промежуточный)
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Стандарт, на основе которого проводилось исследование для составления
          отчета
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Автор
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Статус соответствия результатов отчета выбранному стандарту
        </div>
      </div>,
      <div key={2} className={styles.row}>
        Для удобства навигации список отчетов может быть отфильтрован с помощью
        выпадающих списков:
        <div className={styles.flex_dot}>
          <span className={styles.dot} /> по времени его создания (в зависимости
          от выбранного периода времени меняется тип календаря)
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          типу отчета
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          стандарту исследования
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          статусу
        </div>
      </div>,
      <div key={3} className={styles.row}>
        При нажатии на кнопку скачивания происходит выгрузка отфильтрованного
        списка отчетов в формате xls.
      </div>,
      <div key={4} className={styles.row}>
        Каждый отчет может быть раскрыт до подробного вида нажатием на кнопку со
        стрелкой в правом конце строки. Подробный вид отчета представляет собой
        таблицу показателей, исследуемых лабораторией, с указанием их текущих и
        предыдущих значений, их изменения, а также диаграммы распределения.
      </div>,
      <div key={5} className={styles.row}>
        Значения показателей классифицируются по 4 параметрам - нижняя и верхняя
        границы допуска и контрольные границы внутри поля допуска:
        <div className={styles.flex_dot}>
          <span className={styles.dot} /> Если все показатели в диаграмме
          распределения зеленые - лабораторный отчет имеет статус
          &ldquo;контролируемые значения в пределах стандарта&rdquo; зеленого
          цвета.
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Превышение контрольной границы показывается желтым цветом. Если хотя
          бы один из показателей желтый - весь лабораторный отчет получает
          статус &ldquo;контролируемые показатели в граничных пределах
          стандарта&rdquo; желтого цвета.
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Выход за пределы нижней или верхней границы допуска обозначается
          красным цветом. Если хотя бы один из показателей красный -
          лабораторный отчет получает статус &ldquo;контролируемые показатели не
          соответствуют стандарту&rdquo; красного цвета.
        </div>
      </div>,
      <div key={6} className={styles.row}>
        При нажатии на кнопку &ldquo;Ввести результаты исследования&rdquo;
        открывается модальное окно ввода данных. Необходимо выбрать дату и время
        проведения анализа (по умолчанию выставляется текущее время), тип
        отчета, стандарт исследований и ввести значения показателей. При
        необходимости можно добавить в лабораторный отчет дополнительный
        показатель. После заполнения всех полей, кнопка &ldquo;Сохранить
        результаты&rdquo; станет активна. После ее нажатия введенные результаты
        сразу же отобразятся в последней строке таблицы.
      </div>,
    ],
  },
];

export const TECH_AND_ADMIN_PARAGRAPH_DATA = [
  {
    title: 'Лабораторные исследования: технолог и администратор',
    id: 2,
    rows: [
      <div key={1} className={styles.row}>
        На данном экране содержится список всех лабораторных отчетов в
        хронологическом порядке. У каждого отчета есть:
        <div className={styles.flex_dot}>
          <span className={styles.dot} /> Номер
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Дата и время создания
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Тип (питьевой, поверхностный или промежуточный)
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Стандарт, на основе которого проводилось исследование для составления
          отчета
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Автор
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Статус соответствия результатов отчета выбранному стандарту
        </div>
      </div>,
      <div key={2} className={styles.row}>
        Для удобства навигации список отчетов может быть отфильтрован с помощью
        выпадающих списков:
        <div className={styles.flex_dot}>
          <span className={styles.dot} /> по времени его создания (в зависимости
          от выбранного периода времени меняется тип календаря)
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          типу отчета
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          стандарту исследования
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          статусу
        </div>
      </div>,
      <div key={3} className={styles.row}>
        При нажатии на кнопку скачивания происходит выгрузка отфильтрованного
        списка отчетов в формате xls.
      </div>,
      <div key={4} className={styles.row}>
        Каждый отчет может быть раскрыт до подробного вида нажатием на кнопку со
        стрелкой в правом конце строки. Подробный вид отчета представляет собой
        таблицу показателей, исследуемых лабораторией, с указанием их текущих и
        предыдущих значений, их изменения, а также диаграммы распределения.
      </div>,
      <div key={5} className={styles.row}>
        Значения показателей классифицируются по 4 параметрам: нижняя и верхняя
        границы допуска и контрольные границы внутри поля допуска:
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Если все показатели в диаграмме распределения зеленые - лабораторный
          отчет имеет статус &ldquo;контролируемые значения в пределах
          стандарта&rdquo; зеленого цвета.
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Превышение контрольной границы показывается желтым цветом. Если хотя
          бы один из показателей желтый - весь лабораторный отчет получает
          статус &ldquo;контролируемые показатели в граничных пределах
          стандарта&rdquo; желтого цвета.
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Выход за пределы нижней или верхней границы допуска обозначается
          красным цветом. Если хотя бы один из показателей красный -
          лабораторный отчет получает статус &ldquo;контролируемые показатели не
          соответствуют стандарту&rdquo; красного цвета.
        </div>
      </div>,
    ],
  },
];

export const MONITORING_TECH_PARAGRAPH_DATA = [
  {
    title: 'Мониторинг: технолог',
    id: 1,
    rows: [
      <div key={1} className={styles.row}>
        На данном экране содержится история всех вводов доз реагентов в
        хронологическом порядке. Каждый ввод характеризуется:
        <div className={styles.flex_dot}>
          <span className={styles.dot} /> Датой и временем создания
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Автором
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Типом ввода (первичный или корректировочный)
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Статусом
        </div>
      </div>,
      <div key={2} className={styles.row}>
        Список всех вводов может быть отфильтрован:
        <div className={styles.flex_dot}>
          <span className={styles.dot} /> по времени создания
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          по статусу
        </div>
      </div>,
      <div key={3} className={styles.row}>
        При нажатии на кнопку скачивания происходит выгрузка отфильтрованного
        списка вводов в формате xls.
      </div>,
      <div key={4} className={styles.row}>
        Кнопки &ldquo;Свернуть все&rdquo; и &ldquo;Развернуть все&rdquo; в
        правой части экрана позволяют улучшить процесс передвижения по странице.
      </div>,
      <div key={5} className={styles.row}>
        Каждый ввод может быть раскрыт до подробного вида нажатием на кнопку со
        стрелкой в правом конце строки.
      </div>,
      <div key={6} className={styles.row}>
        Общий процесс работы с данным экраном выглядит следующим образом:
        <div className={styles.flex_dot}>
          <span className={styles.dot} /> Когда в системе появляется новый
          поверхностный отчет, на данном экране появляется контейнер ввода с
          соответствующей информацией. В нем содержится вся необходимая для
          принятия решения информация из поверхностного отчета: исследуемые
          показатели, их значения, изменения этих значений, а также графики для
          отслеживания тренда. Показатели, значения которых превышают
          контрольные границы, отмечены желтым цветом, а те, чьи значения
          выходят за пределы нижней или верхней границы допуска, - красным.
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          После нажатия на кнопку &ldquo;Ввести расчетные дозы реагентов&rdquo;
          появляется модальное окно ввода данных. В нем технологу необходимо
          задать дату и время ввода реагентов, и производительность ВОС.
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          По умолчанию в строках ввода доз реагентов содержатся значения,
          предложенные системой, подобранные с помощью машинного обучения и
          анализа предыдущих отчетов и вводов.
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />В случае, если технолог не согласен с
          предложенными дозами, он может их изменить, но для сохранения ввода
          необходимо сначала ввести комментарий о причинах замены. Строки с
          новыми значениями отмечаются голубой меткой справа.
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Для обеспечения правильности ввода в случае аномального отличия данных
          от средних, имеющихся в системе, технолог должен подтвердить
          правильность ввода нажатием на чекбокс.
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Удалить данные созданного ввода возможно только до появления
          следующего контейнера, - в случае опоздания эта возможность переходит
          на него.
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          После нажатия на кнопку &ldquo;Сохранить расчеты&rdquo;, введенные
          данные появятся в соответствующей таблице в контейнере ввода.
        </div>
      </div>,
      <div key={7} className={styles.row}>
        Когда в системе появляется промежуточный лабораторный отчет, на экране
        отобразится новый контейнер для корректировочного ввода. Технолог
        принимает решение о необходимости изменения данных, введенных в
        первичном вводе, нажатием соответствующей кнопки. Если он выберет не
        изменять данные, то контейнер изменит свой статус на: “Корректировочный
        ввод пропущен”. Если же технолог нажимает на кнопку &ldquo;Внести
        корректировку&rdquo;, появляется модальное окно ввода доз реагентов.
        После сохранения введенных данных, они отображаются в соответствующей
        таблице в контейнере.
      </div>,
      <div key={8} className={styles.row}>
        Цепочка вводов (первичный + корректировочные) находятся в статусе
        ожидания до тех пор, пока не будет выпущен отчет по питьевой воде. Когда
        он будет создан, его статус присваивается последнему совершенному вводу
        реагентов, а все предыдущие выходят из ожидания.
      </div>,
    ],
  },
];

export const MONITORING_LAB_ADMIN_PARAGRAPH_DATA = [
  {
    title: 'Мониторинг: лаборант и администратор',
    id: 2,
    rows: [
      <div key={1} className={styles.row}>
        На данном экране содержится история всех вводов доз реагентов в
        хронологическом порядке. Каждый ввод характеризуется:
        <div className={styles.flex_dot}>
          <span className={styles.dot} /> Датой и временем создания
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Автором
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Типом ввода (первичный или корректировочный)
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Статусом
        </div>
      </div>,
      <div key={2} className={styles.row}>
        Список всех вводов может быть отфильтрован:
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          по времени создания
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          по статусу
        </div>
      </div>,
      <div key={3} className={styles.row}>
        При нажатии на кнопку скачивания происходит выгрузка отфильтрованного
        списка вводов в формате xls.
      </div>,
      <div key={4} className={styles.row}>
        Кнопки &ldquo;Свернуть все&rdquo; и &ldquo;Развернуть все&rdquo; в
        правой части экрана позволяют улучшить процесс передвижения по странице.
      </div>,
      <div key={5} className={styles.row}>
        Каждый ввод может быть раскрыт до подробного вида нажатием на кнопку со
        стрелкой в правом конце строки.
      </div>,
    ],
  },
];

export const REAGENT_LAB_TECH_PARAGRAPH_DATA = [
  {
    title: 'Реагенты: лаборант и технолог',
    id: 1,
    rows: [
      <div key={1} className={styles.row}>
        В таблице на данном экране перечислены все используемые водоканалом
        химические реагенты. У каждого реагента указана следующая информация:
        <div className={styles.flex_dot}>
          <span className={styles.dot} /> Название реагента
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Расход в год, т
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Расход в месяц, т
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Расход в сутки, т
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Удельная стоимость, руб без НДС/т
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Опросный лист
        </div>
      </div>,
      <div key={2} className={styles.row}>
        При наведении на иконку вопросительного знака рядом с названием каждого
        реагента появляется его описание.
      </div>,
      <div key={3} className={styles.row}>
        Если в колонке &ldquo;Опросный лист&rdquo; у реагента прикреплен
        документ, то пользователь может скачать его, нажав мышкой на
        соответствующее название документа в таблице.
      </div>,
    ],
  },
];

export const REAGENT_ADMIN_PARAGRAPH_DATA = [
  {
    title: 'Реагенты: администратор',
    id: 2,
    rows: [
      <div key={1} className={styles.row}>
        В таблице на данном экране перечислены все используемые водоканалом
        химические реагенты. У каждого реагента указана следующая информация:
        <div className={styles.flex_dot}>
          <span className={styles.dot} /> Название реагента
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Расход в год, т
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Расход в месяц, т
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Расход в сутки, т
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Удельная стоимость, руб без НДС/т
        </div>
        <div className={styles.flex_dot}>
          <span className={styles.dot} />
          Опросный лист
        </div>
      </div>,
      <div key={2} className={styles.row}>
        При наведении на иконку вопросительного знака рядом с названием каждого
        реагента появляется его описание.
      </div>,
      <div key={3} className={styles.row}>
        Если в колонке &ldquo;Опросный лист&rdquo; у реагента прикреплен
        документ, то пользователь может скачать его, нажав мышкой на
        соответствующее название документа в таблице.
      </div>,
      <div key={4} className={styles.row}>
        При нажатии на кнопку &ldquo;Добавить новый реагент&rdquo; открывается
        модальное окно, в котором необходимо ввести наименование реагента,
        выбрать единицы измерения из выпадающего списка, задать стоимость и
        добавить описание. Только после заполнения данных полей кнопка
        &ldquo;Добавить новый реагент&rdquo; станет активна.
      </div>,
      <div key={5} className={styles.row}>
        Добавление опросного листа опционально и не обязательно при создании
        нового реагента, т.к. его в дальнейшем можно добавить в режиме
        редактирования. При нажатии на надпись &ldquo;Файл не выбран&rdquo;
        открывается окно для загрузки файлов с устройства. После добавления
        рядом с названием документа появляются две иконки: &ldquo;крестик&rdquo;
        и &ldquo;карандаш&rdquo;. При нажатии на иконку крестика происходит
        удаление прикрепленного файла, а при нажатии на иконку карандаша
        открывается окно для загрузки файлов, в котором можно выбрать новый
        документ.
      </div>,
      <div key={6} className={styles.row}>
        При нажатии на кнопку редактирования в правом конце строки таблицы
        реагентов появляется модальное окно, содержащее всю информацию о
        реагенте, которую по необходимости можно изменить, и затем нажать кнопку
        &ldquo;Сохранить реагент&rdquo; для применения изменений.
      </div>,
      <div key={7} className={styles.row}>
        При нажатии на кнопку удаления в правом конце строки таблицы реагентов
        появляется модальное окно для подтверждения действия. После нажатия на
        кнопку &ldquo;Удалить&rdquo;, указанный реагент будет стерт из таблицы
        без возможности восстановления .
      </div>,
    ],
  },
];

export const INTERFACE_STANDART_TEXT = [
  <p className={styles.mb_15} key={1}>
    Данный экран-справочник содержит все стандарты, применяемые в системе для
    лабораторных исследований воды. Он состоит из двух частей: дерева стандартов
    и таблицы показателей выбранного стандарта.
  </p>,
  <p className={styles.mb_15} key={2}>
    Значения показателей классифицируются по 4 параметрам: нижняя и верхняя
    границы допуска и контрольные границы внутри поля допуска. Превышение
    контрольных границ окрасит значение показателя в лабораторном отчете в
    желтый цвет, отчет будет считаться соответствующим стандарту, однако желтый
    цвет говорит о том, что показатель находится на грани допустимых пределов.
    Выход за пределы нижней или верхней границы допуска обозначается красным
    цветом значения показателя и означает несоответствие качества воды
    выбранному стандарту исследования.
  </p>,
  <p className={styles.mb_15} key={3}>
    Дерево стандартов представляет собой многоуровневую структуру, в основе
    которой лежат базовые (неизменяемые) и пользовательские стандарты. К базовым
    относятся стандарты, утвержденные соответствующими документами, они имеют
    пометку запрета редактирования.
  </p>,
  <p className={styles.mb_15} key={4}>
    Создавать новые пользовательские стандарты может только администратор
    приложения. При нажатии на кнопку &ldquo;Создать новый стандарт&rdquo;
    появляется модальное окно. В нем необходимо выбрать один из типов
    создаваемого стандарта, задать название и, при необходимости, удалить
    ненужные показатели из списка показателей по умолчанию. Рядом с названием
    каждого показателя есть иконка вопросительного знака, при наведении на
    которую, появляется подсказка.
  </p>,
  <p className={styles.mb_15} key={5}>
    После нажатия на кнопку &ldquo;Создать стандарт&rdquo; он появится в дереве
    стандартов в левой части экрана с соответствующей его типу цветовой меткой.
  </p>,
  <p className={styles.mb_15} key={6}>
    При нажатии на кнопку в правом конце строки показателя происходит его
    удаление из стандарта.
  </p>,
  <p className={styles.mb_15} key={7}>
    При нажатии на область добавления нового показателя появляется модальное
    окно, в котором предлагается выбрать способ добавления показателя. Первый
    способ подразумевает собой выбор из выпадающего списка уже имеющихся в
    системе показателей, при этом описание показателя редактировать нельзя.
    Второй способ добавления показателя - создание нового, при его выборе
    появится возможность задать название показателя самостоятельно, а также
    выбрать его единицы измерения из выпадающего списка справа, а затем добавить
    описание. После заполнения всех полей кнопка &ldquo;Добавить
    показатель&rdquo; станет активна.
  </p>,
  <p className={styles.mb_15} key={8}>
    Для применения внесенных изменений необходимо нажать кнопку &ldquo;Сохранить
    изменения&rdquo; в нижнем правом углу экрана, для выхода из режима
    редактирования без внесения изменений - &ldquo;Отменить
    редактирование&rdquo;.
  </p>,
  <p className={styles.mb_15} key={9}>
    В случае, если пользователь хочет перейти на другой стандарт или раздел
    приложения без сохранения текущих изменений, появится модальное окно
    подтверждения.
  </p>,
  <p className={styles.mb_15} key={10}>
    Для удобства навигации имеются кнопки для раскрытия и сворачивания всех
    уровней дерева стандартов.
  </p>,
];

export const INTERFACE_SYNC_REPORT_TEXT = [
  <p className={styles.mb_15} key={1}>
    Экран синхронизации позволяет просматривать связи между вводами активных доз
    реагентов и выпущенными отчетами по питьевой воде, а также редактировать
    связь в случае ошибки синхронизации.
  </p>,
  <p className={styles.mb_15} key={2}>
    С помощью календаря можно выбрать конкретный день для просмотра, а
    горизонтальный скролл временной шкалы позволяет увидеть информацию за
    предыдущий и последующий дни.
  </p>,
  <p className={styles.mb_15} key={3}>
    Водоподготовка - это время технологического цикла, или, другими словами,
    время, через которое после ввода доз реагентов выпускается отчет по питьевой
    воде. Т.к. в зависимости от времени года длительность водоподготовки может
    быть разной, этот параметр можно изменить нажатием соответствующих кнопок
    рядом с числовым значением.
  </p>,
  <p className={styles.mb_15} key={4}>
    Временной лаг - это серая штриховка на временной шкале. У каждого отчета по
    питьевой воде есть свой отрезок временного лага, если навести мышку на
    какой-то отчет, то штриховка подсветится голубым цветом. Этот параметр можно
    изменить нажатием соответствующих кнопок рядом с его числовым значением, а
    так же отключить визуальное отображение нажатием на чекбокс.
  </p>,
  <p className={styles.mb_15} key={5}>
    В случае ошибки синхронизации - если ввод активных доз реагентов не попал во
    временной лаг выпущенного питьевого отчета, между ними не появится
    соединение в виде стрелки. В таком случае мы можем вручную соединить отчет
    по питьевой воде с нужным вводом, протянув между ними стрелку . Тогда
    карточка ввода получит такой же статус, что и отчет.
  </p>,
];

export const INTERFACE_ADMIN_PANEL_TEXT = [
  <p className={styles.mb_15} key={1}>
    Экран &ldquo;Панель администратора&rdquo; доступен для использования только
    администратору водоканала. Экран состоит из двух частей: таблица
    пользователей приложения и панель заявок на регистрацию.
  </p>,
  <p className={styles.mb_15} key={2}>
    В таблице у каждого пользователя отображаются: ФИО, номер телефона,
    электронная почта и его права в приложении. Пользователь может быть
    лаборантом, технологом и администратором одновременно. Для обеспечения
    безопасности администратор не может лишить себя прав администратора, это
    возможно только снятием с него полномочий другим администратором приложения.
  </p>,
  <p className={styles.mb_15} key={3}>
    Администратор может удалить любого пользователя, кроме себя. При нажатии на
    кнопку удаления в правом конце строки пользователя появляется модальное окно
    с подтверждением. После нажатия на кнопку &ldquo;Удалить&rdquo; указанный
    пользователь будет стерт из таблицы.
  </p>,
  <p className={styles.mb_15} key={4}>
    В правой части экрана находится панель с заявками на регистрацию. В случае,
    если в данный момент активных заявок на регистрацию нет, панель скрывается.
    В каждой заявке на регистрацию отображается информация:
    <div className={styles.flex_dot}>
      <span className={styles.dot} />
      Номер заявки
    </div>
    <div className={styles.flex_dot}>
      <span className={styles.dot} />
      ФИО
    </div>
    <div className={styles.flex_dot}>
      <span className={styles.dot} />
      Телефон
    </div>
    <div className={styles.flex_dot}>
      <span className={styles.dot} />
      Адрес электронной почты
    </div>
  </p>,
  <p className={styles.mb_15} key={5}>
    Администратор водоканала на основании предоставленной информации принимает
    решение об одобрении заявки на регистрацию нажатием на одну из кнопок
    &ldquo;Отклонить&rdquo; или &ldquo;Принять&rdquo;. Администратор водоканала
    на основании предоставленной информации принимает решение об одобрении
    заявки на регистрацию нажатием на одну из кнопок &ldquo;Отклонить&rdquo; или
    &ldquo;Принять&rdquo;. В случае принятия заявки в таблице пользователей
    водоканала появляется строка с новым пользователем, которому можно присвоить
    права лаборанта, технолога или администратора. В случае, если ни одна роль
    не была присвоена, новому пользователю будет доступен только просмотр
    содержимого экранов &ldquo;Лабораторные исследования&rdquo;,
    &ldquo;Мониторинг&rdquo;, &ldquo;Реагенты&rdquo; и &ldquo;Стандарты&rdquo;
    до момента изменения его роли администратором.
  </p>,
];
