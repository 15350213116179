import styles from './SystemRequirementsSection.module.scss';

function SystemRequirementsSection() {
  return (
    <section className={styles.container}>
      <h2 className={styles.title}>Системные требования</h2>
      <ol>
        <li className={styles.list}>
          <span className={styles.bold}>1.</span> Для установки требуется ОС
          ubuntu 20.04 или выше
        </li>
        <li className={styles.list}>
          <span className={styles.bold}>2.</span> Для минимальной установки
          необходимо 4 ГБ RAM и 30 ГБ HDD
        </li>
        <li className={styles.list}>
          <span className={styles.bold}>3.</span> Для запуска необходимо
          установить ПО docker, Kubernetes или аналог K3S
        </li>
        <li className={styles.list}>
          <span className={styles.bold}>4.</span> Для получения пакетов Системы
          из репозитория понадобится доступ в Интернет
        </li>
        <li className={styles.list}>
          <span className={styles.bold}>5.</span> Данная инструкция описывает
          установку Системы для целей разработки или тестирования. При установке
          в продуктивной среде необходимо принять меры по защите и
          предотвращению доступа к базам данных и другой инфраструктуре проекта.
        </li>
      </ol>
    </section>
  );
}

export default SystemRequirementsSection;
