import { useMemo, MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import Icon from '../Icon';
import { IconsNames } from '../Icon/constants';

import styles from './Footer.module.scss';
import BubbleGenerator from '../BubbleGenerator';
import { docLinks, links } from '../../Static/Links';

// import { PartnerLogo } from '../../Utils/SvgComponents';

function Footer() {
  function onClickHandler(e: MouseEvent, to: string) {
    if (window.location.pathname.slice(1) === to) {
      e.preventDefault();
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  const navLinks: JSX.Element[] = useMemo(
    () =>
      links.map((menuItem) => {
        const { label, to } = menuItem;
        return (
          <NavLink
            to={to}
            onClick={(e) => onClickHandler(e, to)}
            key={label}
            className={({ isActive }) =>
              clsx(styles.link, {
                [styles.menu_item_active]: isActive,
              })
            }
          >
            {label}
          </NavLink>
        );
      }),
    [],
  );

  const navDocLinks: JSX.Element[] = useMemo(
    () =>
      docLinks.map((menuItem) => {
        const { label, to } = menuItem;
        return (
          <NavLink
            to={to}
            key={label}
            onClick={(e) => onClickHandler(e, to)}
            className={({ isActive }) =>
              clsx(styles.link, {
                [styles.menu_item_active]: isActive,
              })
            }
          >
            {label}
          </NavLink>
        );
      }),
    [],
  );
  return (
    <section className={styles.footer}>
      <Icon
        name={IconsNames.FooterBackground}
        className={styles.footer_background}
      />
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.col_logo}>
            <Icon name={IconsNames.LogoWhite} className={styles.logo} />
            <p className={styles.col_logo__text}>
              Цифровая трансформация технологических процессов
            </p>
          </div>
          <div className={styles.col_links}>{navDocLinks}</div>
          <div className={styles.col_links}>{navLinks}</div>
          {/* Скрыты партнеры, пока не готовы логотипы и сами партнеры
          <div className={styles.col_partners}>
            <span className={styles.col_partners__title}>Наши партнеры</span>
            <div className={styles.col_partners__logos}>
              <PartnerLogo />
              <PartnerLogo />
              <PartnerLogo />
              <PartnerLogo />
            </div>
          </div> */}
        </div>
      </div>
      <span className={styles.delimiter} />
      <div className={styles.footer_reserved}>
        <h4 className={styles.subtitle}>© Водоканал PRO, 2023</h4>
        <h4 className={styles.subtitle}>
          Спроектировано и разработано
          <a href="https://automate-business.ru" className={styles.text_blue}>
            Automate-Business
          </a>
        </h4>
      </div>
      <BubbleGenerator />
    </section>
  );
}

export default Footer;
