import { useLayoutEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import {
  MicroscopeIcon,
  ScienceIcon,
  WaterTapIcon,
} from '../../Utils/SvgComponents';
import ButtonWithBubbles from '../ButtonWithBubbles';
import GlassCard from '../GlassCard';

import styles from './AdvantagesSection.module.scss';
import { scrollIntoView } from '../../Utils/scrollIntoView';

function AdvantagesSection() {
  const waterTapRef = useRef(null);
  const cardGlasRef = useRef(null);
  const waterTapRef2nd = useRef(null);
  const cardGlasRef2nd = useRef(null);
  const waterTapRef3rd = useRef(null);
  const cardGlasRef3rd = useRef(null);

  const cardRef = useRef(null);
  const cardRef2 = useRef(null);
  const cardRef3 = useRef(null);
  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {
    const tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: cardGlasRef.current,
        start: 'top 85%',
        end: '90% 20%',
        scrub: true,
      },
    });
    tl1
      .to(waterTapRef.current, {
        x: -200,
        y: -100,
        opacity: 1,
      })
      .to(waterTapRef.current, { x: 0, y: 0, opacity: 0 });
    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: cardGlasRef2nd.current,
        start: 'top 85%',
        end: '90% 20%',
        scrub: true,
      },
    });
    tl2
      .to(waterTapRef2nd.current, {
        x: -200,
        y: -100,
        opacity: 1,
      })
      .to(waterTapRef2nd.current, { x: 0, y: 0, opacity: 0 });
    const tl3 = gsap.timeline({
      scrollTrigger: {
        trigger: cardGlasRef3rd.current,
        start: 'top 85%',
        end: '90% 20%',
        scrub: true,
      },
    });
    tl3
      .to(waterTapRef3rd.current, {
        x: -200,
        y: -100,
        opacity: 1,
      })
      .to(waterTapRef3rd.current, { x: 0, y: 0, opacity: 0 });
    const cardTl = gsap.timeline({
      scrollTrigger: {
        trigger: cardRef.current,
        start: 'top 85%',
        end: '90% 20%',
      },
    });
    cardTl.fromTo(
      cardRef.current,
      {
        x: -50,
        y: 50,
        opacity: 0,
        duration: 0.4,
      },
      { x: 0, y: 0, opacity: 1, duration: 0.4 },
    );

    const cardTl2 = gsap.timeline({
      scrollTrigger: {
        trigger: cardRef2.current,
        start: 'top 85%',
        end: '90% 20%',
      },
    });
    cardTl2.fromTo(
      cardRef2.current,
      {
        x: 50,
        y: 50,
        opacity: 0,
        duration: 0.4,
      },
      { x: 0, y: 0, opacity: 1, duration: 0.4 },
    );

    const cardTl3 = gsap.timeline({
      scrollTrigger: {
        trigger: cardRef3.current,
        start: 'top 85%',
        end: '90% 20%',
      },
    });
    cardTl3.fromTo(
      cardRef3.current,
      {
        x: -50,
        y: 50,
        opacity: 0,
        duration: 0.4,
      },
      { x: 0, y: 0, opacity: 1, duration: 0.4 },
    );
  }, []);

  return (
    <section className={styles.container}>
      <div className={styles.row}>
        <div ref={cardRef} className={styles.transition}>
          <GlassCard translate="BOTTOM_RIGHT" />
        </div>

        <aside ref={cardGlasRef} className={styles.descr}>
          <h3 className={styles.descr_title}>
            Экономия до 4 часов в сутки ФРВ лаборатории за счет автоматизации
            процесса подбора доз
          </h3>
          <div className={styles.descr_text}>
            Работа технологов и лаборантов находится в единой информационной
            системе, а все результаты исследований можно просмотреть и скачать в
            любой момент.
            <br />
            <br />
            Машинное обучение помогает технологу в процессе подбора необходимого
            количества реагентов.
          </div>
          <div className={styles.flex_wrap}>
            <ButtonWithBubbles
              onClick={scrollIntoView('Form')}
              text="Попробовать"
            />
            <div className={styles.paralax_right} ref={waterTapRef}>
              <WaterTapIcon />
            </div>
          </div>
        </aside>
      </div>
      <div className={styles.row}>
        <aside ref={cardGlasRef2nd} className={styles.descr}>
          <h3 className={styles.descr_title}>
            Снижение расхода реагентов на 10-15% за счет подбора оптимальной
            дозы
          </h3>
          <div className={styles.descr_text}>
            Алгоритм сам высчитывает количество реагентов, которые нужно
            добавить, учитывая стоимость каждого. Такой подход помогает добиться
            питьевого качества воды с наименьшей себестоимостью производства.
          </div>
          <div className={styles.flex_wrap}>
            <ButtonWithBubbles
              onClick={scrollIntoView('Form')}
              text="Попробовать"
            />
            <div ref={waterTapRef2nd} className={styles.paralax_right}>
              <ScienceIcon />
            </div>
          </div>
        </aside>
        <div ref={cardRef2} className={styles.transition}>
          <GlassCard translate="BOTTOM_LEFT" />
        </div>
      </div>
      <div className={styles.row}>
        <div ref={cardRef3} className={styles.transition}>
          <GlassCard translate="TOP_RIGHT" />
        </div>
        <aside ref={cardGlasRef3rd} className={styles.descr}>
          <h3 className={styles.descr_title}>
            Полная синхронизация и ручное управление технологическим процессом
          </h3>
          <div className={styles.descr_text}>
            Гибкая настройка временного лага и длительности водоподготовки
            позволяет
          </div>
          <div className={styles.flex_wrap}>
            <ButtonWithBubbles
              onClick={scrollIntoView('Form')}
              text="Попробовать"
            />
            <div ref={waterTapRef3rd} className={styles.paralax_right}>
              <MicroscopeIcon />
            </div>
          </div>
        </aside>
      </div>
    </section>
  );
}

export default AdvantagesSection;
